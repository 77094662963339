// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Linking, Text, useWindowDimensions, View, TouchableOpacity, NativeSyntheticEvent, TextInputSelectionChangeEventData, ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import * as DocumentPicker from 'expo-document-picker';
import Tooltip from 'react-native-walkthrough-tooltip';
import { Switch } from 'react-native';
import data from '@emoji-mart/data';
import { v4 as uuidv4 } from 'uuid';
import Picker from '@emoji-mart/react';
import { ImageEditor } from "expo-crop-image";
import { SaveFormat, manipulateAsync } from 'expo-image-manipulator';
import { DraxProvider, DraxView, DraxScrollView, DraxList } from "react-native-drax";
import ImageModal, { ImageDetail } from 'react-native-image-modal';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import watermark from 'watermarkjs'

import Theme from '../../global/styles/theme';
import ArrowBack from '../../assets/images/svg/arrowLeft';
import ArrowLeft from '../../assets/images/svg/dotArrowLeft';
import ArrowRight from '../../assets/images/svg/dotArrowRight';
import QuitIcon from '../../assets/images/close.png';
import SearchIcon from '../../assets/images/svg/searchIcon';
import * as S from './styles';
import { getVehiclesList } from '../../services/vehicles';
import { getTags } from '../../services/clients';
import { createUsedArt, getTemplatePreview, updateEditArt, createUsedArtWhatsapp, getArtList, getUsedArtCodition, getUsedPublicationCodition, computedArtsDownload } from '../../services/arts';

import { CoditionModal } from '../../components/CoditionModal';
import { SchedulingModal } from '../SchedulingModal';
import { LoadingModal } from '../../components/LoadingModal';
import { CardImage } from './components/CardImage';
import theme from '../../global/styles/theme';
import { Modal } from '../../components/Modal';
import { getDownloadRemoteFile } from '../../global/utils/functions';
import { InfoModal } from '../../components/TemplateModal/components/InfoModal';
import { formatTags } from './consts';
import { TypeSocialModal } from '../../components/TypeSocialModal';
import { SelectFrames } from './components/SelectFrames'

import { getImageLink, getImageLinkMultiples } from '../../services/uploads';
import { closestIndexTo } from 'date-fns/esm';
import { Button } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { generateDescriptionUser, getDescription, getDescriptionUser } from '../../services/descriptions';
import { isMobile } from 'react-device-detect';
import { calculateAspectRatioFit, getImageDimensions } from '../../utils/functions';
import { InputInternal } from '../../components/InputInternal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaFormatMedia } from '../../global/utils/formsSchems';
import { ModalSend } from '../../components/ModalSend';
import { SimpleModal } from '../../components/SimpleModal';

interface ModalInfoProps {
    id?: number,
    thumb?: string,
    title?: string,
    type?: string,
    tags?: any,
    id_art?: number,
    id_vehicle?: number,
    preview_thumb?: string,
    message: string,
    is_birthday_message_default?: boolean,
}

interface BirthDaysProps {
    age: number;
    birth_date: string;
    city: string;
    id: number;
    name: string;
    is_send: boolean;
    phones: Array<string>;
}

interface TempleteModalProps {
    modalVisible: boolean;
    setModalVisible: (modalVisible: boolean) => void;
    modalInfo?: ModalInfoProps;
    updateList: () => {};
    setTemplateModalVisible?: (modalVisible: boolean) => void,
    typeMethod?: string,
    direct?: boolean,
    birthday?: boolean,
    birthInfo?: BirthDaysProps;
    closeModalBirth?: () => void;
    idVehicle?: string;
}

interface TemplateOptionProps {
    thumb?: string,
    id?: number,
}

interface CreateUsedArtProps {
    dateSend: string,
    isFinished: boolean,
    idArt: number,
    idUser: number,
    idVehicle: number,
}

interface ModalCoditionProps {
    titleModal: string,
    descriptionModal: string,
    typeModal: 'error' | 'info' | 'success',
    buttonText: string,
    modalVisible: boolean,
    setModalVisible: (modalVisible: boolean) => void,
    buttonFunction: () => void,
}

interface ContactModalProps  {
    description: string,
}

export function UseTemplateModal(props: TempleteModalProps) {
    const {
        modalInfo: {
            id,
            thumb,
            title,
            titleVehicle,
            type,
            tags,
            id_art,
            id_vehicle,
            preview_thumb,
            is_vehicle,
            message,
            is_removebg,
            is_overlapbg,
            is_input_title,
            dimensiones,
        },
        modalVisible,
        setModalVisible,
        updateList,
        setTemplateModalVisible,
        typeMethod,
        direct,
        birthday = false,
        birthInfo,
        closeModalBirth,
        handleOpenDrawer,
        idVehicle
    } = props;
    const resolver = { resolver: yupResolver(schemaFormatMedia), mode: 'onChange'};
    const { control, formState: { errors, isValidating }, getValues } = useForm(resolver);
    const width = useWindowDimensions().width;
    const fontBlack = Theme.colors.lowBlack;
    const textCarColor = Theme.colors.gray[600];
    const whiteFont = Theme.colors.white;
    const purpleFont = Theme.colors.purple[500];
    const marginRight = width <= 900 ? 0 : 16;
    const userInfo = useSelector((state) => state.user.user);
    const imagesUser = userInfo?.user?.images || [];
    const framesUser = userInfo?.user?.frames || [];
    const imageModalRef = useRef<ImageDetail>(null);
    const inputsInitialFrame = [
        {
            id: 1,
            txt: '',
        },
        {
            id: 2,
            txt: '',
        },
        {
            id: 3,
            txt: '',
        },
        {
            id: 4,
            txt: '',
        },
        {
            id: 5,
            txt: '',
        },
    ];

    const [searchText, setSearchText] = useState('');
    const [schedulingModalVisible, setSchedulingModalVisible] = useState(false);
    const [page, setPage] = useState(0);
    const [photosForTemplate, setPhotosForTemplate] = useState<any>(null);
    const [photosForTemplateLocal, setPhotosForTemplateLocal] = useState<any[]>([]);
    const [photosForTemplateLocalUpdate, setPhotosForTemplateLocalUpdate] = useState<any[]>([]);
    const [photosForTemplateSelected, setPhotosForTemplateSelected] = useState<any[]>([]);
    const [photosForTemplateSelectedBackup, setPhotosForTemplateSelectedBackup] = useState<any[]>([]);
    const [photosForTemplateSelectedAll, setPhotosForTemplateSelectedAll] = useState<any[]>([]);
    const [vehiclesList, setVehiclesList] = useState<any[]>([]);
    const [vehiclesListLocal, setVehiclesListLocal] = useState<any[]>([]);
    const [imagesSelecteds, setImagesSelecteds] = useState<any[]>([]);
    const [framesSelecteds, setFramesSelecteds] = useState<any[]>('');
    const [frameActual, setFrameActual] = useState(null);
    const [lastframesSelecteds, setLastFramesSelecteds] = useState<any[]>([]);
    const [coditionPagination, setCoditionPagination] = useState(false);
    const [selectedTemplateOption, setSelectedTemplateOption] = useState<TemplateOptionProps>({} as TemplateOptionProps);
    const [modalInfo, setModalInfo] = useState<ModalCoditionProps>({} as ModalCoditionProps);
    const [modalInfoSend, setModalInfoSend] = useState(false);
    const [isPublishSite, setIsPublishSite] = useState(false);
    const [loadingImage, setLoadingImage] = useState(true);
    const [loadingVisible, setLoadingVisible] = useState(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [description, setDescription] = useState('');
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [nameVehicle, setNameVehicle] = useState('');
    const [tagsSelected, setTagsSelected] = useState([]);
    const [isEnabled, setIsEnabled] = useState(false);
    const [modalEnabledRemoveBg, setModalEnabledRemoveBg] = useState(false);
    const uriPhotoCodition = selectedTemplateOption?.thumb ? { uri: selectedTemplateOption?.thumb } : { uri: direct ? '' : thumb};
    const [heightBoxArea, setHeightBoxArea] = useState(300);
    const uriPhoto = preview_thumb?.split('|')[0] || uriPhotoCodition;
    const coditionBackgroundButton = buttonCondition() ? Theme.colors.gray[500] : Theme.colors.purple[500];
    const allImages: Array<any> = imagesUser.map((v) => {
        return {
            id: v,
            photo: v,
        }
    });
    const [currentTabPosition, setCurrentTabPosition] = useState(1);
    const [isRemoveBgCapaOrMoldura, setIsRemoveBgCapaOrMoldura] = useState(false);
    const [allFrames, setAllFrames] = useState([]);
    const [saveAllImages, setSaveAllImages] = useState<any>([]);
    const [saveAllFrames, setSaveAllFrames] = useState<any>([]);
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [contactModalInfo, setContactModalInfo] = useState<ContactModalProps>({} as ContactModalProps);
    const [emojiSelected, setEmojiSelected] = useState<Array<string>>([]);
    const [positionActualKeyboard, setPositionActualKeyboard] = useState(undefined);
    const [allTags, setAllTags] = useState<any>(null);
    const [socialMediaVisible, setSocialMediaVisible] = useState(false);
    // const [isOrder, setIsOrder] = useState(false);
    const [errorModalLimitePhoto, setErrorModalLimitePhoto] = useState(false);
    const [typeSocial, setTypeSocial] = useState('');
    const [whatsappDescription, setWhatsappDescription] = useState(props.modalInfo?.is_birthday_message_default ? '' : userInfo?.user?.birthday_message);
    const [textDefaultUser, setTextDefaultUser] = useState(true);
    const [renderFrame, setRenderFrame] = useState<1 | 2 | 3>(1);
    const [typeArt, setTypeArt] = useState('');
    const [textsForFrame, setTextsForFrame] = useState(inputsInitialFrame);
    const [imagesWithFrameSelected, setImagesWithFrameSelected] = useState([]);
    const [loadingApplyFrame, setLoadingApplyFrame] = useState(false);
    const [existType, setExistType] = useState(false);
    const [allVehiclePhotos, setAllVehiclePhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [existVehicle, setExistVehicle] = useState(false);
    const [loadingDraxList, setLoadingDraxList] = useState(false);
    const [isVisibleImageEditor, setIsVisibleImageEditor] = useState(false);
    const [stepCrop, setStepCrop] = useState(0);
    const [openCrop, setOpenCrop] = useState(false);
    const [previewFrame, setPreviewFrame] = useState([]);
    const [imagesWithFrameSelectedCrop, setImagesWithFrameSelectedCrop] = useState<any>(null);
    const [loadingCrop, setLoadingCrop] = useState(false);
    const [framesOrCapeRemoveBg, setFramesOrCapeRemoveBg] = useState(false);
    const [photoRemoveBgActual, setPhotoRemoveBgActual] = useState(null);
    const [titleVehicleEdit, setTitleVehicleEdit] = useState(null);
    const [titleVehicleEditSave, setTitleVehicleEditSave] = useState(null);
    const [toolTipVisible, setToolTipVisible] = useState(false);
    const [focusScreen, setFocusScreen] = useState(true);

    useEffect(() => {
        if (props.modalInfo?.logoSelectedPub) {
            setImagesSelecteds([{ id: props.modalInfo?.logoSelectedPub, photo: props.modalInfo?.logoSelectedPub }]);
        }
        if (props.modalInfo?.descriptionPub) {
            setDescription(props.modalInfo?.descriptionPub);
            setDescriptionLength(props.modalInfo?.descriptionPub?.length);
        }
    }, [props.modalInfo]);

    useEffect(() => {
        if (props.modalInfo?.photoVehicleSelectedPub && (vehiclesList?.list || []).length) {
            const atualVehicle = [
                {
                    id: `${id_vehicle}-${props.modalInfo?.photoVehicleSelectedPub}`,
                    photo: props.modalInfo?.photoVehicleSelectedPub,
                    photo_base: props.modalInfo?.photoVehicleSelectedPub,
                },
            ];
            setPhotosForTemplateSelected(atualVehicle);
            setPhotosForTemplateSelectedBackup(atualVehicle);
            const getVehicle = (vehiclesList?.list || [])?.find((v) => v.id === id_vehicle);
            setPhotosForTemplate({
                ...getVehicle,
                photo: getVehicle?.photo?.split('|').map((v) => {
                    return {
                        id: `${getVehicle.id}-${v}`,
                        photo: v,
                        photo_base: v,
                    };
                }),
            });
        }
    }, [props.modalInfo, vehiclesList]);

    useEffect(() => {
        if (!saveAllImages.length && allImages) {
            setSaveAllImages(allImages);
        }
    }, [allImages]);

    useEffect(() => {
        if (!saveAllFrames.length && allFrames) {
            setSaveAllFrames(allFrames);
        }
    }, [allFrames]);

    useEffect(() => {
        if (modalVisible) {
            const timeOutId = setTimeout(() => {
                coditionList();
            }, 500);

            return () => clearTimeout(timeOutId);
        }
    }, [modalVisible, searchText, page]);


    const [focusGeneratorTemplate, setFocusGeneratorTemplate] = useState(true);

    useEffect(() => {
      const verifyDefaultVehicle = focusGeneratorTemplate && vehiclesList && (typeMethod !== 'publishStock' ? is_vehicle : true);
        if (saveAllImages.length > 0) {
            setDefaultLogo(verifyDefaultVehicle);
        }
    }, [saveAllImages]);

    // useEffect(() => {
    //     // !is_vehicle &&
    //     if ((typeMethod !== 'publishStock' && imagesSelecteds.length > 0) || birthday) {
    //         const timeOutId = setTimeout(() => {
    //             generatorTemplate();
    //         }, 500);
    //         return () => clearTimeout(timeOutId);
    //     }
    // }, [imagesSelecteds, photosForTemplate, photosForTemplateSelected]);

    // const [focusGeneratorTemplate, setFocusGeneratorTemplate] = useState(false);

    // useEffect(() => {
    //     if (focusGeneratorTemplate) {
    //         const timeOutId = setTimeout(() => {
    //             if (typeof titleVehicleEdit === 'string' && typeMethod !== 'publishStock') {
    //                 generatorTemplate();
    //             }
    //         }, 500);

    //         return () => clearTimeout(timeOutId);
    //     }
    //     setFocusGeneratorTemplate(true);
    // }, [titleVehicleEdit]);

    function coditionList() {
        const timeOutId = setTimeout(() => {
            getCarList(searchText);
        }, 500);

        return () => clearTimeout(timeOutId);
    }

    const toggleSwitch = (value) => {
        setIsEnabled(value);
    };

    const handleModalRemoveBg = () => {
        setModalEnabledRemoveBg(!modalEnabledRemoveBg);
        setPhotoRemoveBgActual(null);
    };

    const toggleSwitchWhatsapp = (value) => {
        setWhatsappDescription(!value ? '' : userInfo?.user?.birthday_message);
        setTextDefaultUser(value);
    };

    const [loadingVehiclesList, setLoadingVehiclesList] = useState(false);

    async function getCarList(text: string) {
        const { result } = await getVehiclesList(page + 1, userInfo.user.id, 20, text);
        setVehiclesList(result);
        setCoditionPagination(page < result.qtdPages);
        const verifyDefaultVehicle = focusGeneratorTemplate && result?.list?.length > 0 && (typeMethod !== 'publishStock' ? is_vehicle : true);

        if (verifyDefaultVehicle && !loadingVehiclesList) {
            setLoadingVehiclesList(true);
            setDefaultVehicle();
        }
        if (typeMethod !== 'publishStock' && (!(result || []).length || !is_vehicle && id)) {
            setRenderFrame(2);
        }
    }

    async function getCarPaginationList(pageNumber: number) {
        const { result } = await getVehiclesList(1, userInfo.user.id, 20, searchText);
        if (pageNumber < result.qtdPages) {
            setVehiclesList(result.list);
            setPage(pageNumber);
            setCoditionPagination(true);
        } else if(pageNumber === result.qtdPages) {
            setVehiclesList(result.list);
            setPage(pageNumber);
            setCoditionPagination(false);
        } else {
            setCoditionPagination(false);
        }
    }

    function closeAllModal() {
        changeModalInfoVisible();
        setModalVisible(false);
        if (typeMethod !== 'edit') {
            setTemplateModalVisible(false);
        } else {
            updateList();
        }
    }

    async function generateImagesWithFrames() {

        if (framesSelecteds[0]?.photo) {
            const photos = [];
            const promises = [];
            const isDiferrent = framesSelecteds[0]?.photo === lastframesSelecteds[0]?.photo
            if (!lastframesSelecteds.length) {
                setLastFramesSelecteds(framesSelecteds[0]?.photo);
                setPhotosForTemplateSelectedBackup(photosForTemplateSelected);
            }
            setLoadingImage(false);

            const options = {
                init(img) {
                    img.crossOrigin = 'anonymous';
                },
            };

            (lastframesSelecteds.length
                ? photosForTemplateSelectedBackup
                : photosForTemplateSelected
            ).forEach(async (el) => {
                const promise = new Promise(async (resolve, reject) => {
                    function myRadRotateFunction(alpha) {
                        return function (first, second) {
                            var context = first.getContext('2d');
                            context.save();
                            context.globalAlpha = alpha;
                            context.drawImage(
                                second,
                                0,
                                0,
                                context.canvas.width,
                                context.canvas.height,
                            );
                            context.restore();
                            return first;
                        };
                    }
                    await watermark(
                        [
                            el?.photo,
                            framesSelecteds[0]?.photo,
                        ],
                        options,
                    )
                        .image(myRadRotateFunction(1))
                        .then((img) => {
                            photos.push({
                                ...el,
                                photo: img.src,
                            });
                            resolve();
                        })
                        .catch((error) => {
                            reject();
                            setLoadingImage(true);
                        });
                });

                promises.push(promise);
            });

            Promise.all(promises)
                .then(() => {
                    setPhotosForTemplateSelected(photos);
                    setLoadingImage(true);
                })
                .catch(() => setLoadingImage(true));
        } else {
            setPhotosForTemplateSelected(photosForTemplateSelectedBackup);
        }
    }

    // useEffect(() => {
    //     const type = getValues('type');
    //     let typeCondition = 'feed';
    //     if (type === '2') {
    //         typeCondition = 'story';
    //     }
    //     setTypeFormat(typeCondition);
    // }, [isValidating]);

    // useEffect(() => {
    //     getTemplatePhoto();
    // }, [imagesSelecteds, photosForTemplateSelected, openGallery]);

    const getAllTags = async () => {
        const get = await getTags(userInfo.user.id, photosForTemplate?.id);
        if ((get?.result?.list || [])[0]) {
            const list = formatTags(get.result.list[0]);
            const removeEmptyHeaders = list.headers.filter((v) => v.id);
            const removeEmptyVehicle = list.vehicle.filter((v) => v.id);
            const removeEmptySellers = list.sellers.filter((v) => v.id);
            const removeEmptyValues = list.values.filter((v) => v.id);
            const removeEmptyInfo = list.info.filter((v) => v.id);
            const removeEmptyOptionals = await list.optionals.filter((v) => v.id);
            const allFiltersTags = {
                headers: removeEmptyHeaders,
                vehicle: removeEmptyVehicle,
                sellers: removeEmptySellers,
                values: removeEmptyValues,
                info: removeEmptyInfo,
                optionals: removeEmptyOptionals,
            };
            setAllTags(allFiltersTags);
        }
    };

    function getType() {
        if (currentTabPosition === 2) {
            return 'logotipos';
        }
        if (currentTabPosition === 3) {
            return 'datas';
        }
        return 'default';
    }

    async function getFrames() {
        setAllFrames([]);
        const getArts = await getArtList(
            1,
            'all',
            userInfo.user.id,
            '',
            typeArt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            getType(),
        );
        if (getArts?.status === 200) {
            setAllFrames(getArts?.result?.list || []);
        }
    }

    function handleClickOpenCrop(urls_vehicles: Array<string>, isPhotoBase: boolean, itemArt: any) {
        // if (itemArt?.dimensiones === null) {
        //     applyFrame(urls_vehicles, isPhotoBase, itemArt?.id);
        // } else {
        //     setOpenCrop(true);
        //     applyFrameCrop(urls_vehicles, itemArt?.id);
        // }
        setOpenCrop(true);
        applyFrameCrop(urls_vehicles, itemArt?.id);
    }

    async function applyFrameCrop(urls_vehicles: Array<string>, idArt?: number, itemRemoveBg?: any) {
        setLoadingApplyFrame(true);
        const formatTexts = textsForFrame.map((v) => v.txt);
        if (typeArt === 'molduras') {
            if (!imagesWithFrameSelectedCrop?.id && !itemRemoveBg) {
                for (const v of urls_vehicles) {
                    const response = await getTemplatePreview(
                        idArt || framesSelecteds,
                        userInfo.user.id,
                        photosForTemplate?.id,
                        imagesSelecteds[0]?.photo,
                        [{ url: v?.photo, removeBg: v?.removeBg || false }],
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        formatTexts,
                        frameActual?.is_input_title ? titleVehicleEdit : undefined,
                        // undefined,
                        // v?.removeBg ? v?.photo_base : undefined
                    );
                    setPreviewFrame(previewFrame => [...previewFrame, {
                        ...v,
                        photo: response?.result?.base64,
                    }]);
                }
                setLoadingApplyFrame(false);
            } else {
                const imageCrop = urls_vehicles.find((v) => v.id === (itemRemoveBg || imagesWithFrameSelectedCrop)?.id);
                const response = await getTemplatePreview(
                    idArt || framesSelecteds,
                    userInfo.user.id,
                    photosForTemplate?.id,
                    imagesSelecteds[0]?.photo,
                    [{ url: imageCrop?.photo, removeBg: imageCrop?.removeBg || false }],
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    formatTexts,
                    frameActual?.is_input_title ? titleVehicleEdit : undefined,
                    // undefined,
                    // imageCrop?.removeBg ? imageCrop?.photo : undefined,
                );
                const finishedPromise = previewFrame.map((v) => {
                    if (v.id === (itemRemoveBg || imagesWithFrameSelectedCrop)?.id) {
                        return {
                            ...v,
                            photo: response?.result?.base64,
                        };
                    }
                    return v;
                });
                setLoadingApplyFrame(false);
                setPreviewFrame(finishedPromise);
            }
        }
        if (typeArt !== 'molduras') {
            const formatCapas = urls_vehicles.map((v) => ({
                removeBg: v.removeBg || false,
                url: v.photo,
            }));
            const response = await getTemplatePreview(
                idArt || framesSelecteds,
                userInfo.user.id,
                photosForTemplate?.id,
                imagesSelecteds[0]?.photo,
                formatCapas,
                undefined,
                undefined,
                undefined,
                undefined,
                formatTexts,
                frameActual?.is_input_title ? titleVehicleEdit : undefined,
                // formatCapas,
                // formatPhotoVehicleSelectedRemoveBg?.length ? formatPhotoVehicleSelectedRemoveBg[0] : undefined,
            );
            setPreviewFrame([
                {
                    id: uuidv4(),
                    modified: true,
                    photo: response?.result?.base64,
                    photo_base: response?.result?.base64,
                }
            ]);
            setLoadingApplyFrame(false);
        }
    }

    async function applyFrame(urls_vehicles: Array<string>, isPhotoBase: boolean, idArt?: number): Array<string> {
        setLoadingApplyFrame(true);
        let newList = [];
        const formatTexts = textsForFrame.map((v) => v.txt);
        if (typeArt === 'molduras') {
            const finishedPromise = [];
            for (const v of urls_vehicles) {
                const response = await getTemplatePreview(
                    idArt || framesSelecteds,
                    userInfo.user.id,
                    photosForTemplate?.id,
                    imagesSelecteds[0]?.photo,
                    [{ url: isPhotoBase ? v?.photo_base : v?.photo, removeBg: v?.removeBg || false }],
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    formatTexts,
                    frameActual?.is_input_title ? titleVehicleEdit : undefined,
                    // undefined,
                    // v?.removeBg ? (isPhotoBase ? v?.photo_base : v?.photo) : undefined
                );
                finishedPromise.push({
                    ...v,
                    photo: response?.result?.base64,
                });
            }

            newList = (typeMethod === 'publishStock' ? photosForTemplateLocalUpdate : photosForTemplateSelected).map((el) => {
                const currentPhoto = finishedPromise.find((v) => v?.id === el?.id);

                if (currentPhoto) {
                    return {
                        ...el,
                        photo: currentPhoto?.photo,
                    };
                }

                return el;
            });
        }
        if (typeArt !== 'molduras') {
            const formatCapas = imagesWithFrameSelected.map((v) => ({
                removeBg: v.removeBg || false,
                url: v.photo,
            }));
            const response = await getTemplatePreview(
                idArt || framesSelecteds,
                userInfo.user.id,
                photosForTemplate?.id,
                imagesSelecteds[0]?.photo,
                formatCapas,
                undefined,
                undefined,
                undefined,
                undefined,
                formatTexts,
                frameActual?.is_input_title ? titleVehicleEdit : undefined,
                // formatCapas,
                // formatPhotoVehicleSelectedRemoveBg?.length ? formatPhotoVehicleSelectedRemoveBg[0] : undefined,
            );
            // const allIds = urls_vehicles.map((v) => v.id);
            // const removePhotoSelected = photosForTemplateLocalUpdate.filter((el) => !allIds.includes(el?.id));
            newList = [
                {
                    id: uuidv4(),
                    modified: true,
                    photo: response?.result?.base64,
                    photo_base: response?.result?.base64,
                },
                ...photosForTemplateLocalUpdate,
            ];
        }

        emptyStates();
        if (typeMethod === 'publishStock') {
            setPhotosForTemplateLocalUpdate(newList);
        } else {
            setPhotosForTemplateSelected(newList);
        }
        setLoadingApplyFrame(false);
    }

    function emptyStates() {
        setFramesSelecteds('');
        setFrameActual(null);
        setTextsForFrame(inputsInitialFrame);
        setImagesWithFrameSelected([]);
    }

    useEffect(() => {
        if (photosForTemplate?.id) {
            getAllTags();
        } else {
            setAllTags(null);
        }
    }, [photosForTemplate]);

    // useEffect(() => {
    //     if (framesSelecteds && imagesWithFrameSelected.length && typeArt !== 'molduras') {
    //         applyFrame(imagesWithFrameSelected);
    //     }
    // }, [framesSelecteds]);

    useEffect(() => {
        if (renderFrame === 3) {
            getFrames();
        }
        emptyStates();
    }, [renderFrame, currentTabPosition]);

    const ref = useRef(null);

    useEffect(() => {
        if (renderFrame === 2) {
            setTimeout(() => {
                ref.current?.scrollTo({
                    y: 0,
                    animated: true,
                  });
            }, 300);
        }
    }, [renderFrame]);

    useEffect(() => {
        if (typeMethod !== 'publishStock' && dimensiones === null) {
            setRenderFrame(2)
        }
    }, [typeMethod]);

    function applyFrameUpdate() {
        // if (framesSelecteds && imagesWithFrameSelected.length) {
        //     applyFrame(imagesWithFrameSelected);
        // }
    }

    async function generatorTemplate(arrayPhotosCrop?: Array<any>, isEnabledRemoveBg?: boolean, titleActual?: string, idVehicle?: number, imagesSelectedsLocal?: Array<any>) {
        setLoadingImage(false);
        // const photoUrl = (arrayPhotosCrop || photosForTemplateSelected).map((el) => el.photo);
        const logoImageUrl = (imagesSelectedsLocal || imagesSelecteds).map((el) => el.photo);

        // console.log('photoUrl', photoUrl);
        const formatPhotoUrl = (arrayPhotosCrop || photosForTemplateSelected).map((v) => ({
            url: v.photo,
            removeBg: isEnabledRemoveBg ? true : v.removeBg || false,
        }));
        const formatTexts = textsForFrame.map((v) => v.txt);

        const { result, status, message, error } = await getTemplatePreview(
            id_art || id,
            userInfo.user.id,
            idVehicle || photosForTemplate?.id,
            logoImageUrl.join('|'),
            formatPhotoUrl,
            // !isEnabledRemoveBg ? (formatPhotoUrl?.length > 0 ? formatPhotoUrl : photoUrl).join('|') : undefined,
            birthInfo?.birth_date ? whatsappDescription : (userInfo?.user?.message || ''),
            birthInfo?.birth_date,
            (birthInfo?.name || ' ').split(' ')[0],
            isEnabledRemoveBg,
            formatTexts,
            is_input_title ? titleActual || titleVehicleEdit : undefined,
            // undefined,
            // formatPhotoVehicleSelectedRemoveBg?.length > 0 && !isEnabledRemoveBg ? formatPhotoVehicleSelectedRemoveBg.join('|') : undefined,
        );

        if (status === 200 || status === 201) {
            setSelectedTemplateOption({
                thumb: result?.base64,
                id: photosForTemplate?.id,
            });
            setNameVehicle(result?.vehicle?.title || '');
            setLoadingImage(true);
            // setOpenGallery(false);
        } else {
            const modalProps: ModalCoditionProps = {
                typeModal: 'info',
                titleModal: 'Atenção!',
                descriptionModal: message,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
                setLoadingImage(true);
                setPhotosForTemplateSelected([]);
            }, 500);
        }
    }

    // async function getTemplatePhoto() {
    //     if ((imagesSelecteds.length || photosForTemplateSelected.length) && !openGallery && !direct) {
    //         generatorTemplate();
    //     }
    // }

    async function publicateTemplate(body: CreateUsedArtProps) {
        setSocialMediaVisible(false);
        setLoadingVisible(true);
        const { status, message, error, result } = birthday
            ? await createUsedArtWhatsapp(body)
            : typeMethod !== 'edit'
            ? await createUsedArt(body)
            : await updateEditArt(id, body);

        if (status === 200 || status === 201) {
            try {
                setLoadingVisible(false);
                if (typeMethod === 'publishStock') {
                    resetAll('noClose');
                }
                if (birthInfo?.phones?.length) {
                    const reducePhones = birthInfo?.phones.reduce((r,s) => r > s.length ? r : s.length, 0);
                    const phones = birthInfo?.phones.filter(pl => pl.length == reducePhones);
                    const infoUrl = `https://api.whatsapp.com/send?phone=${phones[0]?.replace(/\D/g, '')}&text=https://aniversariante.midiacarros.com.br/?acc=${body.idCustomer}_${result.art_used.id}`;
                    Linking.openURL(infoUrl);
                }
                const modalProps: ModalCoditionProps = {
                    typeModal: 'success',
                    titleModal: 'Sucesso',
                    descriptionModal: message,
                    buttonText: 'Continuar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: closeAllModal,
                };

                if (birthday) {
                    closeModalBirth();
                }

                setTimeout(() => {
                    if (body.isSend) {
                        setIsPublishSite(body.type === 'banner-site');
                        return setModalInfoSend(true);
                    }
                    return setModalInfo(modalProps);
                }, 500);
            } catch (error) {
                setLoadingVisible(false);
                const modalProps: ModalCoditionProps = {
                    typeModal: 'error',
                    titleModal: 'Erro',
                    descriptionModal: message || '',
                    buttonText: 'Continuar',
                    modalVisible: true,
                    setModalVisible: changeModalInfoVisible,
                    buttonFunction: closeAllModal,
                };
                setTimeout(() => {
                    setModalInfo(modalProps);
                }, 500);
            }
        } else if (error === 'LIMIT') {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setLoadingVisible(false);

            setTimeout(() => {
                setContactModalVisible(true);
                setContactModalInfo(contactModalInfo);
            }, 500);
        } else {
            setLoadingVisible(false);
            const errors = result?.errorData?.error?.error_user_msg || message || 'Ocorreu um erro ao publicar';
            const modalProps: ModalCoditionProps = {
                typeModal: result?.type === 'instagram-feed' || result?.type === 'instagram-story' ? 'info' : 'error',
                titleModal: result?.type === 'instagram-feed' || result?.type === 'instagram-story' ? 'Publicado parcialmente' : 'Erro',
                descriptionModal: result?.type === 'instagram-feed' || result?.type === 'instagram-story' ? `Publicado com sucesso no facebook. Porém no instagram ${errors}` : errors,
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };

            setTimeout(() => {
                setModalInfo(modalProps);
            }, 500);
        }
    };

    function changeModalInfoVisible() {
        setModalInfo({ modalVisible: false } as ModalCoditionProps);
    }

    function resetAll(type: string) {
        if (type !== 'noClose') {
            setModalVisible(false);
        }
        // if (width <= 860 && handleOpenDrawer) {
        //     handleOpenDrawer();
        // }
        setSelectedTemplateOption({ thumb: '', id: null });
        setVehiclesListLocal([]);
        setPhotosForTemplateLocal([]);
        setPhotosForTemplateLocalUpdate([]);
        setRenderFrame(1);
        setDescription('');
        setTagsSelected([]);
        setPositionActualKeyboard(undefined);
        setPhotosForTemplateSelected([]);
        setImagesWithFrameSelected([]);
        setPhotosForTemplateSelectedBackup([]);
        setPhotosForTemplateSelectedAll([]);
        setFramesSelecteds('');
        setTypeArt('');
        setExistType(false);
        setPhotosForTemplate(null);
    }

    function handleBackdropPress() {
        if (renderFrame === 1) {
            resetAll();
        }
        if (renderFrame === 2) {
            resetAll(typeMethod === 'publishStock' ? 'noClose' : '');
        }
        if (renderFrame === 3) {
            resetPhotoVehicle();
        }
    }

    async function schedulingButtonPress() {
        const { status, message, result } = await getUsedPublicationCodition(userInfo?.user?.id);
        if (status === 200) {
            if (descriptionLength > 2200) {
                return setToolTipVisible(true);
            }
            return setSchedulingModalVisible(true);
        } else {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setContactModalVisible(true);
            setContactModalInfo(contactModalInfo);
        }
    }

    async function handlePublicNowPress() {
        const { status, message, result } = await getUsedPublicationCodition(userInfo?.user?.id);
        if (status === 200) {
            if (descriptionLength > 2200) {
                return setToolTipVisible(true);
            }
            return setSocialMediaVisible(true);
        } else {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setContactModalVisible(true);
            setContactModalInfo(contactModalInfo);
        }
    }


    function publish(data: any, type: any) {
        const formatTexts = textsForFrame.map((v) => v.txt);
        const body: any = typeMethod !== 'edit' ? {
            dateSend: null,
            isFinished: true,
            idArt: direct ? null : id,
            idUser: userInfo.user.id,
            idVehicle: selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id,
            description,
            previewThumb: direct ? (data || typeMethod === 'publishStock' ? photosForTemplateLocal || photosForTemplateSelected || [] : photosForTemplateSelected || [])?.map((v) => v.photo).join(',').replaceAll(',', '|') : (selectedTemplateOption?.thumb || null),
            photoVehicleSelected: (data || typeMethod === 'publishStock' ? photosForTemplateLocal || photosForTemplateSelected || [] : photosForTemplateSelected || [])?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
            logoSelected: imagesSelecteds[0]?.photo || '',
            isSend: true,
            wasPublishedFromStock: direct || false,
            type: type || typeSocial,
            titleVehicle: titleVehicleEdit,
            texts: formatTexts,
        } : {
            dateSend: null,
            isFinished: true,
            idUser: userInfo.user.id,
            idVehicle: selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id,
            description,
            previewThumb: direct ? (data || typeMethod === 'publishStock' ? photosForTemplateLocal || photosForTemplateSelected || [] : photosForTemplateSelected || [])?.map((v) => v.photo).join(',').replaceAll(',', '|') : (selectedTemplateOption?.thumb || null),
            photoVehicleSelected: (data || typeMethod === 'publishStock' ? photosForTemplateLocal || photosForTemplateSelected || [] : photosForTemplateSelected || [])?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
            logoSelected: imagesSelecteds[0]?.photo || '',
            isSend: true,
            wasPublishedFromStock: direct || false,
            type: type || typeSocial,
            titleVehicle: titleVehicleEdit,
            texts: formatTexts,
        };

        publicateTemplate(body);
    }

    async function getUrlPhotos(){
        setSocialMediaVisible(false);
        setLoadingVisible(true)
        const response = await Promise.all(photosForTemplateSelected.map(async (el: any) => {
            const type = el?.photo.split(/[:;]/).map(str => str.trim())
            const body = {
                name: el?.id,
                type: type[1],
                base64: el?.photo,
            };

            const { result } = await getImageLink(body);

            return result;
        })).then((result) => {
            const newList = result.map((el) => {
                return {
                    photo: el,
                };
            });

            return newList
        }).catch((error) => {
            setLoadingVisible(false);
            toast.error(`${error}`, theme.toast);
        });

        setPhotosForTemplateSelected(response)
        return response;
    }

    async function confirmSocialPress(type) {
        const arrayVerify = typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected;
        const getPhoto = arrayVerify?.length > 0 ? arrayVerify[0]?.photo?.includes('base64') : false;
        if (getPhoto) {
            await getUrlPhotos().then((result) => {
                setPhotosForTemplateSelected(result)
                publish(result, type === 'banner-site' ? 'banner-site' : null);
            });
        } else {
            publish(null, type === 'banner-site' ? 'banner-site' : null);
        }
    }

    function normalizeNewlines(text) {
        return text.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
    }

    function onChangeTextDescription(txt: string) {
        txt = normalizeNewlines(txt);
        setDescription(txt);
        setDescriptionLength(txt.length);
    }

    function handlePublicAfterPress() {
        const formatTexts = textsForFrame.map((v) => v.txt);
        if (descriptionLength > 2200) {
            return setToolTipVisible(true);
        } else {
            const body: any = typeMethod !== 'edit' ? {
                dateSend: null,
                isFinished: false,
                idArt: direct ? null : id,
                idUser: userInfo.user.id,
                idVehicle: selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id,
                description,
                previewThumb: direct ? (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)[0]?.photo : (selectedTemplateOption?.thumb || null),
                photoVehicleSelected: (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)[0]?.photo || '',
                logoSelected: imagesSelecteds[0]?.photo || '',
                isSend: false,
                wasPublishedFromStock: direct || false,
                titleVehicle: titleVehicleEdit,
                texts: formatTexts,
            } : {
                dateSend: null,
                isFinished: false,
                idUser: userInfo.user.id,
                idVehicle: selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id,
                description,
                previewThumb: direct ? (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)[0]?.photo : (selectedTemplateOption?.thumb || null),
                photoVehicleSelected: (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)[0]?.photo || '',
                logoSelected: imagesSelecteds[0]?.photo || '',
                isSend: false,
                wasPublishedFromStock: direct || false,
                titleVehicle: titleVehicleEdit,
                texts: formatTexts,
            };

            publicateTemplate(body);
        }
    }

    function handleFinishPress() {
        const formatTexts = textsForFrame.map((v) => v.txt);
        if (descriptionLength > 2200) {
            return setToolTipVisible(true);
        } else {
            const body: any = typeMethod !== 'edit' ? {
                dateSend: null,
                isFinished: true,
                idArt: direct ? null : id,
                idUser: userInfo.user.id,
                idVehicle: selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id,
                description,
                previewThumb: direct ? (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)?.map((v) => v.photo).join(',').replaceAll(',', '|') : (selectedTemplateOption?.thumb || null),
                photoVehicleSelected: (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
                logoSelected: imagesSelecteds[0]?.photo || '',
                isSend: false,
                wasPublishedFromStock: direct || false,
                titleVehicle: titleVehicleEdit,
                texts: formatTexts,
            } : {
                dateSend: null,
                isFinished: true,
                idUser: userInfo.user.id,
                idVehicle: selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id,
                description,
                previewThumb: direct ? (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)?.map((v) => v.photo).join(',').replaceAll(',', '|') : (selectedTemplateOption?.thumb || null),
                photoVehicleSelected: (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected)?.map((v) => v.photo).join(',').replaceAll(',', '|') || '',
                logoSelected: imagesSelecteds[0]?.photo || '',
                isSend: false,
                wasPublishedFromStock: direct || false,
                titleVehicle: titleVehicleEdit,
                texts: formatTexts,
            };

            publicateTemplate(body);
        }
    }

    function removeAllSelected() {
        setPhotosForTemplateSelected([]);
        setPhotosForTemplateLocal([]);
        setVehiclesListLocal([]);
        setPhotosForTemplateLocalUpdate([]);
        setPhotosForTemplateSelectedAll([]);
    }

    function renderLogos() {
        if (!saveAllImages.length ||  typeMethod === 'publishStock') {
            return null;
        }
        return saveAllImages.map((v) => {
            const photoOption = { uri: v.photo };
            const selectedCodition = imagesSelecteds.filter((e) => e.id === v.id).length > 0;

            const handleTemplatePress = async () => {
                const newArray = !selectedCodition ? [{ id: v.id, photo: v.photo }]
                : imagesSelecteds.filter((item) => v.id !== item.id);
                setImagesSelecteds(newArray);
                generatorTemplate(undefined, undefined, undefined, undefined, newArray);
            }
            return (
                <S.ButtonLogo onPress={handleTemplatePress} key={v?.id} isSelected={selectedCodition} disabled={!loadingImage}>
                    <S.Logo source={photoOption} onContextMenu={(e) => e.preventDefault()} />
                </S.ButtonLogo>
            );
        })
    }

    // function confirmVehicles() {
    //     setRenderFrame(2);
    //     setPhotosForTemplateLocal([...photosForTemplateSelected, ...photosForTemplateLocal]);
    //     setExistVehicle(true);
    // }

    async function LoadPhoto(concat: boolean, notUpdateLocal?: boolean, applyGeneratorTemplate?: boolean) {
        const typeDocument = { type: 'image/png, image/jpg, image/jpeg', multiple: true };
        const result = await DocumentPicker.getDocumentAsync(typeDocument);
        const files: FileList = result.output;
        if (Object.keys(files).length > 10) {
            if (openGallery) {
                return setErrorModalLimitePhoto(!errorModalLimitePhoto);
            }
            const modalProps: ModalCoditionProps = {
                typeModal: 'error',
                titleModal: 'Erro',
                descriptionModal: 'Não é possível adicionar mais de 10 imagens por vez.',
                buttonText: 'Fechar',
                modalVisible: true,
                setModalVisible: changeModalInfoVisible,
                buttonFunction: changeModalInfoVisible,
            };
            return setModalInfo(modalProps);
        }
        setLoading(true);
        setErrorModalLimitePhoto(false);

        if (result.type === 'success') {
            const allFiles = await Promise.all(
                Object.keys(files).map(
                (_key, i) =>
                    new Promise((resolve) => {
                        const reader = new FileReader();
                        const file = files[i];

                        reader.onload = () => {
                            resolve({
                                base64: reader.result as string,
                                name: file.name,
                                type: file.type
                            });
                        };
                        reader.readAsDataURL(file);
                    })
                )
            );
            const uploadAllFiles = await getImageLinkMultiples(allFiles);
            if (uploadAllFiles?.result?.length) {
                const allVehiclesUpload = uploadAllFiles.result.map((v) => {
                    const newPhoto = {
                        id: v,
                        photo: v,
                        photo_base: v,
                    };
                    return newPhoto;
                })
                setLoading(false);
                if (applyGeneratorTemplate) {
                    generatorTemplate(allVehiclesUpload);
                    return null;
                }
                setVehiclesListLocal([...vehiclesListLocal, ...allVehiclesUpload]);
                if (!notUpdateLocal) {
                    setPhotosForTemplateLocal(concat ? [...photosForTemplateLocal, ...allVehiclesUpload] : allVehiclesUpload);
                }
                if (!openGallery && renderFrame !== 2) {
                    setExistVehicle(false);
                    setRenderFrame(2);
                }
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    function renderButtonCape() {
        if (renderFrame === 2) {
            return (
                <S.Button
                    width={width}
                    buttonColor={
                        renderFrame === 3
                            ? Theme.colors.red[600]
                            : coditionBackgroundButton
                    }
                    borderColor={
                        renderFrame === 3
                            ? Theme.colors.red
                            : coditionBackgroundButton
                    }
                    marginRight={5}
                    onPress={() => changeStepFrameImage('capa')}
                    style={{ width: width < 600 ? renderFrame === 3 ? '24%' : '32%' : 150 }}
                >
                    <S.TextDefault
                        fontSize={18}
                        fontFamily="regular"
                        color={whiteFont}
                    >
                        Capa
                    </S.TextDefault>
                </S.Button>
            );
        }
    }

    function renderButtonMoldure() {
        if (renderFrame === 2) {
            return (
                <S.Button
                    width={width}
                    buttonColor={
                        renderFrame === 3
                            ? Theme.colors.red[600]
                            : coditionBackgroundButton
                    }
                    borderColor={
                        renderFrame === 3
                            ? Theme.colors.red
                            : coditionBackgroundButton
                    }
                    marginRight={5}
                    onPress={() => changeStepFrameImage('molduras')}
                    style={{ width: width < 600 ? renderFrame === 3 ? '24%' : '32%' : 150 }}
                >
                    <S.TextDefault
                        fontSize={18}
                        fontFamily="regular"
                        color={whiteFont}
                    >
                        Moldura
                    </S.TextDefault>
                </S.Button>
            );
        }
    }

    function rendeButtonFrames() {
        if (typeMethod !== 'publishStock') {
            return null;
        }

        return (
            <S.ButtonFramesContainer renderFrame={renderFrame}>
                {renderButtonCape()}
                {renderButtonMoldure()}
                {renderFrame === 3 ? (
                    <S.Button
                        width={width}
                        buttonColor={
                            renderFrame === 3
                                ? Theme.colors.red[600]
                                : coditionBackgroundButton
                        }
                        borderColor={
                            renderFrame === 3
                                ? Theme.colors.red
                                : coditionBackgroundButton
                        }
                        marginRight={5}
                        onPress={resetPhotoVehicle}
                        style={{ width: width < 600 ? renderFrame === 3 ? '24%' : '32%' : 150 }}
                    >
                        <S.TextDefault
                            fontSize={18}
                            fontFamily="regular"
                            color={whiteFont}
                        >
                            Cancelar
                        </S.TextDefault>
                    </S.Button>
                ) : null}
                <S.Button
                    width={width}
                    buttonColor={coditionBackgroundButton}
                    borderColor={coditionBackgroundButton}
                    marginRight={5}
                    onPress={renderFrame === 3 ? changeStepFrameImage : applyDownloadPhotoSelected}
                    style={{ width: width < 600 ? renderFrame === 3 ? '24%' : '32%' : 150 }}
                >
                    <S.TextDefault
                        fontSize={18}
                        fontFamily="regular"
                        color={whiteFont}
                    >
                        {renderFrame === 3 ? 'Finalizar' : 'Baixar'}
                    </S.TextDefault>
                </S.Button>
            </S.ButtonFramesContainer>
        );
    }

    function renderFrames() {
        if (
            !saveAllFrames.length ||
            userInfo?.user?.platform === 'revenda_prod' ||
            typeMethod !== 'publishStock' ||
            !photosForTemplateSelected.length
        ) {
            return null;
        }

        return saveAllFrames.map((v) => {
            const photoOption = { uri: v.photo };
            const selectedCodition =
                framesSelecteds.filter((e) => e.id === v.id).length > 0;

            const handleTemplatePress = async () => {
                const newArray = !selectedCodition
                    ? [{ id: v.id, photo: v.photo }]
                    : framesSelecteds.filter((item) => v.id !== item.id);
                setFramesSelecteds(newArray);
            };

            return (
                <S.ButtonLogo
                    onPress={handleTemplatePress}
                    key={v?.id}
                    isSelected={selectedCodition}
                    disabled={!loadingImage}>
                    <S.Logo source={photoOption} onContextMenu={(e) => e.preventDefault()} />
                </S.ButtonLogo>
            );
        });
    }

    async function setDefaultVehicle() {
        if (typeMethod === 'publishStock' && idVehicle) {
            const { result } = await getVehiclesList(page + 1, userInfo.user.id, 20, '', Number(idVehicle));
            const el = result?.list?.length > 0 ? result?.list[0] : {};
            const templatePhoto = {
                ...el,
                photo: el?.photo?.split('|').map((v) => {
                    return {
                        id: `${el.id}-${v}`,
                        photo: v,
                        photo_base: v,
                    }
                }),
            }
            setPhotosForTemplate(templatePhoto);
            setFocusGeneratorTemplate(false);
            if (templatePhoto?.photo?.length) {
                setPhotosForTemplateSelected(templatePhoto?.photo);
                setRenderFrame(2);
                setPhotosForTemplateLocal(templatePhoto?.photo);
                setPhotosForTemplateSelectedAll(templatePhoto?.photo);
                const modelAndVersion = `${templatePhoto?.model || ''} ${templatePhoto?.version || ''}`;
                const newTitle = templatePhoto?.title ? templatePhoto?.title?.substring(0, 35) : `${modelAndVersion?.substring(0, 35) || ''}`;
                setTitleVehicleEdit(newTitle);
                setTitleVehicleEditSave(newTitle);
                setExistVehicle(true);
                if (uriPhoto && !direct) {
                    setTimeout(() => {
                        ref.current?.scrollTo({
                            y: 0,
                            animated: true,
                        });
                    }, 500);
                }
            }
        } else if (typeMethod !== 'publishStock' && vehiclesList?.list?.length > 0) {
            const el = (vehiclesList?.list || [{}])[0];
            const templatePhoto = {
                ...el,
                photo: el?.photo?.split('|').map((v) => {
                    return {
                        id: `${el.id}-${v}`,
                        photo: v,
                        photo_base: v
                    };
                }),
            };
            setPhotosForTemplate(templatePhoto?.id ? templatePhoto : null);
            setFocusGeneratorTemplate(false);
            if (templatePhoto?.photo?.length) {
                setPhotosForTemplateSelected([templatePhoto?.photo[0]]);
                const modelAndVersion = `${templatePhoto?.model || ''} ${templatePhoto?.version || ''}`;
                const newTitle = templatePhoto?.title ? templatePhoto?.title?.substring(0, 35) : `${modelAndVersion?.substring(0, 35) || ''}`;
                setTitleVehicleEdit(newTitle);
                setTitleVehicleEditSave(newTitle);
                setExistVehicle(true);
                setTimeout(() => {
                    generatorTemplate([templatePhoto?.photo[0]], undefined, newTitle, templatePhoto?.id);
                }, 300);
            }
        }
    }

    function setDefaultLogo(verifyDefaultVehicle: Boolean) {
        const newArray = [
            { id: saveAllImages[0].id, photo: saveAllImages[0].photo },
        ];
        setImagesSelecteds(newArray);
        if (!verifyDefaultVehicle && !window.location.href.includes('idVehicle=')) {
            generatorTemplate(undefined, undefined, undefined, undefined, newArray);
        }
    }

    function RenderCardCar(el: any, onPress: () => void, source: any, isSelected: boolean, showVehiclesListLocal: boolean, idListAll?: any) {
        return (
            <S.CarArea style={{ height: showVehiclesListLocal ? 158 : 255 }} isSelected={isSelected} onPress={onPress} key={el.id} disabled={!loadingImage}>
                <S.Column>
                    <S.ImageCar onContextMenu={(e) => e.preventDefault()} style={{ borderBottomLeftRadius: showVehiclesListLocal ? 5 : 0, borderBottomRightRadius: showVehiclesListLocal ? 5 : 0 }} resizeMode='cover' source={source} />
                    {idListAll?.length > 0 ? RenderNumberArea(el.id, isSelected, idListAll) : null}
                    {showVehiclesListLocal ? null : (
                        <>
                            <S.FooterImageCar>
                                <S.TextDefault
                                    fontSize={14}
                                    fontFamily="bold"
                                    color={theme.colors.white}
                                >
                                    {el?.brand ? el.brand : ''}
                                </S.TextDefault>
                                <S.TextDefault
                                    fontSize={14}
                                    fontFamily="bold"
                                    color={theme.colors.white}
                                >
                                    {el?.year_model ? el.year_model : ''}
                                </S.TextDefault>
                            </S.FooterImageCar>
                            <S.TextDefault
                                fontSize={12}
                                fontFamily="regular"
                                color={theme.colors.gray[400]}
                            >
                                {el.model && `${el.model} `}
                                {el.version && `| ${el.version} `}
                                {/* {el.model && `| ${el.model} `}
                                {el.version && `| ${el.version} `}
                                {el.board && `| ${el.board} `} */}
                            </S.TextDefault>
                        </>
                    )}
                </S.Column>
                {showVehiclesListLocal ? null : (
                    <S.TextDefault
                        fontSize={14}
                        fontFamily="bold"
                        color={theme.colors.gray[800]}
                        style={{ textAlign: 'right' }}
                    >
                        {el?.title ? `${el.title.substring(0, 20)}...` : ''}
                    </S.TextDefault>
                )}
            </S.CarArea>
        );
    }

    function RenderCarList(showVehiclesListLocal: boolean) {
        // if (!(vehiclesList?.list || []).length || !is_vehicle && id) {
        //     return null;
        // }

        if (showVehiclesListLocal && openGallery) {
            return vehiclesListLocal.map((el) => {
                const existPhoto = photosForTemplateLocal.find((v) => v.id === el.id);
                const selectedCoditionAll = photosForTemplateSelectedAll.filter((e) => e.id === el.id).length > 0;
                const idListAll = photosForTemplateSelectedAll.map((e) => e.id);
                function viewPhotosForTemplate() {
                    if (existPhoto?.id) {
                        const remove = photosForTemplateLocal.filter((v) => v.id !== el.id);
                        setPhotosForTemplateLocal(remove);
                    } else {
                        setPhotosForTemplateLocal([...photosForTemplateLocal, el]);
                    }
                    const newArrayAll = !selectedCoditionAll
                        ? [
                              ...photosForTemplateSelectedAll,
                              { id: el.id, photo: el.photo, photo_base: el.photo },
                          ]
                        : photosForTemplateSelectedAll.filter(
                              (item) => el.id !== item.id,
                          );
                    setPhotosForTemplateSelectedAll(newArrayAll);
                }

                return RenderCardCar(el, viewPhotosForTemplate, { uri: el.photo }, selectedCoditionAll, showVehiclesListLocal, idListAll);
            })
        }
        // slice(page === 0 ? 0 : page * 9, (page + 1) * 9)

        return (vehiclesList?.list || []).map((el) => {
            const allPhotosVehicles = el.photo?.split('|') || [];
            const photoOption = { uri: allPhotosVehicles[0] || '' };
            const selectedCodition = photosForTemplate?.id === el?.id;

            function viewPhotosForTemplate() {
                if (!selectedCodition) {
                    const templatePhoto = {
                        ...el,
                        photo: el?.photo?.split('|').map((v) => {
                            return {
                                id: `${el.id}-${v}`,
                                photo: v,
                                photo_base: v,
                            }
                        }),
                    }
                    if (typeMethod === 'publishStock') {
                        setVehiclesListLocal([]);
                    }
                    setPhotosForTemplateLocal([]);
                    setPhotosForTemplate(templatePhoto);
                    setAllVehiclePhotos(templatePhoto?.photo);
                    if (templatePhoto?.photo?.length) {
                        setPhotosForTemplateSelected([templatePhoto?.photo[0]]);
                        setPhotosForTemplateSelectedBackup([templatePhoto?.photo[0]]);
                        setPhotosForTemplateSelectedAll([templatePhoto?.photo[0]]);
                    }
                }
                setOpenGallery(true);
            }

            return RenderCardCar(el, viewPhotosForTemplate, photoOption, selectedCodition, showVehiclesListLocal);
        })
    }

    function RenderNumberArea(codition: number, coditionNumber: boolean, numberList: Array<number>) {
        if (!coditionNumber) {
            return null;
        }

        return (
            <S.NumberCar>
                <S.NumberText>
                    {numberList.indexOf(codition) + 1}
                </S.NumberText>
            </S.NumberCar>
        );
    }

    function renderGallery() {
        return photosForTemplate?.photo?.map((el) => {
            const selectedCodition = photosForTemplateSelected.filter((e) => e.id === el.id).length > 0;
            const idList = photosForTemplateSelected.map((e) => e.id);

            const handleTemplatePress = async () => {
                const newArray = !selectedCodition
                    ? [
                          ...photosForTemplateSelected,
                          { id: el.id, photo: el.photo, photo_base: el.photo },
                      ]
                    : photosForTemplateSelected.filter(
                          (item) => el.id !== item.id,
                      );
                setPhotosForTemplateSelected(newArray);
                setPhotosForTemplateSelectedBackup(newArray);
                // setOpenGallery(false);
            }

            return (
                <S.CarArea onPress={handleTemplatePress} key={el.id} isSelected={selectedCodition}>
                    <S.ImageArea>
                        <S.ImageCar onContextMenu={(e) => e.preventDefault()} resizeMode='contain' source={{ uri: el.photo }} />
                        {RenderNumberArea(el.id, selectedCodition, idList)}
                    </S.ImageArea>
                </S.CarArea>
            );

        })
    }

    function handleSelectAll() {
        const allDataTemplate = photosForTemplate?.photo?.map((el) => ({
            id: el.id,
            photo: el.photo,
            photo_base: el.photo
        }))
        setPhotosForTemplateSelected(allDataTemplate);
        setPhotosForTemplateSelectedBackup(allDataTemplate);
        setPhotosForTemplateSelectedAll([...allDataTemplate, ...vehiclesListLocal]);

        setPhotosForTemplateLocal(vehiclesListLocal);
    }

    function handleDeselectAll() {
        setPhotosForTemplateSelected([]);
        setPhotosForTemplateSelectedBackup([]);
        setPhotosForTemplateSelectedAll([]);
        setPhotosForTemplateLocal([]);
    }

    function renderGalleryPublishStock() {
        return photosForTemplate?.photo?.map((el) => {
            const selectedCodition = photosForTemplateSelected.filter((e) => e.id === el.id).length > 0;
            const selectedCoditionAll = photosForTemplateSelectedAll.filter((e) => e.id === el.id).length > 0;
            const idList = photosForTemplateSelected.map((e) => e.id);
            const idListAll = photosForTemplateSelectedAll.map((e) => e.id);

            const handleTemplatePress = async () => {
                const newArray = !selectedCodition
                    ? [
                          ...photosForTemplateSelected,
                          { id: el.id, photo: el.photo, photo_base: el.photo },
                      ]
                    : photosForTemplateSelected.filter(
                          (item) => el.id !== item.id,
                      );
                setPhotosForTemplateSelected(newArray);
                setPhotosForTemplateSelectedBackup(newArray);

                const newArrayAll = !selectedCoditionAll
                    ? [
                          ...photosForTemplateSelectedAll,
                          { id: el.id, photo: el.photo, photo_base: el.photo },
                      ]
                    : photosForTemplateSelectedAll.filter(
                          (item) => el.id !== item.id,
                      );
                setPhotosForTemplateSelectedAll(newArrayAll);
                // setOpenGallery(false);
            }

            return (
                <S.CarArea onPress={handleTemplatePress} key={el.id} isSelected={selectedCoditionAll}>
                    <S.ImageArea>
                        <S.ImageCar resizeMode='contain' source={{ uri: el.photo }} onContextMenu={(e) => e.preventDefault()} />
                        {RenderNumberArea(el.id, selectedCoditionAll, idListAll)}
                    </S.ImageArea>
                </S.CarArea>
            );

        })
    }

    function RenderNextIcon() {
        if ((page + 1) === (vehiclesList?.qtdPages || 0)) {
            return null;
        }
        const getNextPage = () => {
            setPage(page + 1);
        }

        return (
            <S.ArrowButtonArea type='right' onPress={getNextPage} width={width}>
                <ArrowRight />
            </S.ArrowButtonArea>
        );
    }

    function RenderBackIcon() {
        if (page === 0) {
            return null;
        }

        const backPage = () => {
            setPage(page - 1);
        }

        return (
            <S.ArrowButtonArea type='left' onPress={backPage} width={width}>
                <ArrowLeft />
            </S.ArrowButtonArea>
        );
    }

    function handleOpenModal() {
        imageModalRef.current?.setState({
            isOpen: true,
            origin: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            },
        });
    }

    function RenderImageArea() {
        if (!loadingImage) {
            return (
                <S.LoadingImage size='large' color={Theme.colors.purple[500]} width={width} />
            )
        }

        return (
            <>
                <S.ImageContainer onPress={handleOpenModal} style={{ height: direct ? 'auto' : 500 }}>
                    <S.TemplateImage resizeMode='contain' source={uriPhoto} width={width} onContextMenu={(e) => e.preventDefault()} />
                </S.ImageContainer>
                <S.ModalContent
                    ref={imageModalRef}
                    modalRef={imageModalRef}
                    resizeMode="contain"
                    imageBackgroundColor="transparent"
                    source={uriPhoto}></S.ModalContent>
            </>
        );
    }

    function RenderImageCar() {
        if (!loadingImage) {
            return (
                <S.LoadingImage size='large' color={Theme.colors.purple[500]} width={width} />
            )
        }
        const list = typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected;
        if (typeMethod === 'publishStock' && loadingDraxList) {
            return (
                <S.AdjustLoading>
                    <Loading />
                </S.AdjustLoading>
            );
        }
        if (list.length) {
            return (
                <DraxProvider>
                    <DraxList
                        // columnWrapperStyle={{
                        //     flexWrap: "wrap",
                        //     flex: 1,
                        //     flexDirection: 'row',
                        //     // minHeight: 490,
                        // }}
                        contentContainerStyle={{
                            // minHeight: 490,
                            // height: 'auto',
                            // backgroundColor: 'green',
                            flexDirection: 'row',
                            flexWrap: "wrap",
                            width: '100%',
                        }}
                        // onItemDragStart={() => {
                        //     setIsOrder(true);
                        // }}
                        // onItemDragEnd={() => {
                        //     setIsOrder(false);
                        // }}
                        // onPointerEnter={() => {
                        //     setIsOrder(true);
                        // }}
                        // longPressDelay={1000}
                        // onTouchStart={() => {
                        //     setIsOrder(true);
                        // }}
                        // onStartShouldSetResponder={() => {
                        //     setIsOrder(true);
                        // }}
                        // scrollEnabled={!isOrder}
                        data={typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected}
                        // numColumns={5}
                        reorderable={!isMobile}
                        onItemReorder={({ fromIndex, toIndex }) => {
                            if (typeMethod === 'publishStock') {
                                const newData = photosForTemplateLocal.slice();
                                newData.splice(toIndex, 0, newData.splice(fromIndex, 1)[0]);
                                setPhotosForTemplateLocal([]);
                                setLoadingDraxList(true);
                                setTimeout(() => {
                                    setLoadingDraxList(false);
                                    setPhotosForTemplateLocal(newData);
                                }, 100);
                            } else {
                                const newData = photosForTemplateSelected.slice();
                                newData.splice(toIndex, 0, newData.splice(fromIndex, 1)[0]);
                                setPhotosForTemplateSelected(newData);
                            }
                        }}
                        renderItemContent={({ item, index }) => {
                            const onPressClose = () => {
                                if (typeMethod === 'publishStock') {
                                    const removeId = photosForTemplateLocal.filter((v) => v.id !== item.id);
                                    setPhotosForTemplateLocal(removeId);
                                    const removeIdLocal = vehiclesListLocal.filter((v) => v.id !== item.id);
                                    setVehiclesListLocal(removeIdLocal);
                                } else {
                                    const removeId = photosForTemplateSelected.filter((v) => v.id !== item.id);
                                    setPhotosForTemplateSelected(removeId);
                                }
                            };
                            const onPressDownload = async () => {
                                const { status, message, result } = await getUsedPublicationCodition(userInfo?.user?.id, 'true');
                                if (status === 200) {
                                    const resultComputed = await computedArtsDownload(userInfo?.user?.id, [item.photo]);
                                    if (resultComputed.status === 201) {
                                        getDownloadRemoteFile([item.photo], setLoadingDownload);
                                    } else {
                                        const contactModalInfo: ContactModalProps = {
                                            description: resultComputed.message,
                                        }
                                        setContactModalVisible(true);
                                        setContactModalInfo(contactModalInfo);
                                    }
                                } else {
                                    const contactModalInfo: ContactModalProps = {
                                        description: message,
                                    }
                                    setContactModalVisible(true);
                                    setContactModalInfo(contactModalInfo);
                                }
                            };
                            return (
                                <CardImage onPressDownload={onPressDownload} item={item} index={index} setData={typeMethod === 'publishStock' ? setPhotosForTemplateLocal : setPhotosForTemplateSelected} data={typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected} grid={list.length} isClose onPressClose={onPressClose} />
                            );
                        }}
                        keyExtractor={(item) => item.photo}
                    />
                </DraxProvider>
            );
        }
        return null;
    }

    function buttonDownloadImage() {
        return (
            <S.Button
                width={width}
                onPress={downloadPreviewThumbPub}
                style={{ width: width < 480 ? (width < 430 ? (width < 400 ? (width < 360 ? (width < 340 ? 80 : 100) : 120) : 150) : 180) : 200, marginBottom: 0, marginLeft: width < 480 ? 2 : 20 }}>
                <S.TextDefault
                    fontSize={width < 400 ? (width < 360 ? (width < 340 ? 11 : 14) : 15) : 18}
                    fontFamily="regular"
                    color={purpleFont}>
                    Baixar imagem
                </S.TextDefault>
            </S.Button>
        )
    }

    let boxAreaRef = null;

    function addDataToInput(data: string, key: string) {
        if (typeof positionActualKeyboard?.start === 'number' && typeof positionActualKeyboard?.end === 'number') {
            const initialInput = description.substring(0, positionActualKeyboard.start);
            const finalInput = description.substring(positionActualKeyboard.start, descriptionLength);
            const newDescription = `${initialInput}${data || ''}${finalInput}`;
            onChangeTextDescription(newDescription);
            if (key) {
                setTagsSelected([...tagsSelected, key]);
            }
            boxAreaRef?.focus();
            const newPosition = {
                start: positionActualKeyboard.start + data.length,
                end: positionActualKeyboard.end + data.length,
            };
            setPositionActualKeyboard(newPosition)
        } else {
            onChangeTextDescription(`${description}${data}`);
            if (key) {
                setTagsSelected([...tagsSelected, key]);
            }
        }
    }

    function getCurrentPositionActualKeyboard(event: NativeSyntheticEvent<TextInputSelectionChangeEventData>) {
        const { selection } = event.nativeEvent;
        setPositionActualKeyboard(selection)
    }

    async function addNewDescription(index: number, listUnique?: Array<any>) {
        try {
            const descriptionUnique = (listUnique || listDescriptions)[index];
            if (!descriptionUnique?.id) {
                return null;
            }
            setLoading(true);
            const body = {
                idDescription: descriptionUnique.id,
                idPosition: descriptionUnique.position,
                idVehicle: selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id,
            };
            if (!body.idVehicle) {
                delete body.idVehicle;
            }
            const { result } = await generateDescriptionUser(body);
            addDataToInput(result?.descriptionFormatted || '');
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    const [listDescriptions, setListDescriptions] = useState(null);
    const [disabledAutoCompleteDesc, setDisabledAutoCompleteDesc] = useState(false);

    useEffect(() => {
        getDescriptionList();
    }, []);

    useEffect(() => {
        if (idVehicle?.length > 0 && photosForTemplate?.id && listDescriptions?.length > 0 && !disabledAutoCompleteDesc) {
            setDisabledAutoCompleteDesc(true);
            addNewDescription(2, listDescriptions);
        }
    }, [idVehicle, photosForTemplate?.id, listDescriptions?.length]);

    const getDescriptionList = async () => {
        const responseAdm = await getDescription();
        const responseUser = await getDescriptionUser(userInfo.user.id);
        const listUser = responseUser?.result?.list;
        const listAdm = responseAdm?.result?.list;
        const formatList = [1,2,3].map((v) => {
            const getItemUser = listUser.find((e) => e.position === v);
            if (getItemUser?.id) {
                return {
                    ...getItemUser,
                    type: 'client',
                };
            }
            const getItemAdm = listAdm.find((e) => e.position === v);
            if (getItemAdm?.id) {
                return {
                    ...getItemAdm,
                    type: 'adm',
                };
            }
            return null;
        }).filter((e) => e).sort((a, b) => {
            if (a.position < b.position) return -1;
            if (a.position > b.position) return 1;
            return 0;
        });

        setListDescriptions([]);
        setListDescriptions(formatList || []);
    };

    function renderEmoji(params:type) {
        return (
            <S.InputPickerArea width={width} style={{ marginTop: width <= 600 ? 38 : 10, marginBottom: width <= 600 ? 38 : 16 }}>
                <S.TextDefault
                    fontSize={16}
                    fontFamily="600"
                    color={textCarColor}
                >
                    Inserir Emoji:
                </S.TextDefault>
                <S.Label>Abrir Seletor de Emoji</S.Label>
                <Switch
                    trackColor={{ false: '#767577', true: theme.colors.purple[500] }}
                    thumbColor="#f4f3f4"
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={toggleSwitch}
                    value={isEnabled}
                    style={{ marginTop: 5, marginLeft: 5 }}
                />
                {!isEnabled ? null : (
                    <Picker data={data} onEmojiSelect={(emoji) => addDataToInput(emoji.native)} />
                )}
            </S.InputPickerArea>
        );
    }

    function renderDescriptionArea(modeTemplate?: boolean) {
        if (birthday) {
            return null;
        }

        return (
            <S.TemplateInfoArea width={width}>
                {width <= 600 ? null : renderEmoji()}
                <S.AdjustBox width={width}>
                    <S.AdjustBoxTag width={width}>
                        <S.AdjustHeader>
                            <S.TextDefault
                                fontSize={16}
                                fontFamily="600"
                                color={textCarColor}
                            >
                                Digite o texto para sua postagem na caixa abaixo:
                            </S.TextDefault>
                            {(!allTags?.headers[0]?.id && !userInfo?.user?.default_text) || modeTemplate ? null : (
                                <S.TouchableOpacityEmpty
                                    onPress={() => addDataToInput(userInfo?.user?.default_text || allTags?.headers[0]?.id, userInfo?.user?.default_text ? 'Inserir texto padrão' : allTags?.headers[0]?.name)}
                                    focus={tagsSelected.includes(userInfo?.user?.default_text  ? 'Inserir texto padrão' : allTags?.headers[0]?.name)}
                                >
                                    <S.Tag>
                                        {userInfo?.user?.default_text ? 'Inserir texto padrão' : allTags?.headers[0]?.name}
                                    </S.Tag>
                                </S.TouchableOpacityEmpty>
                            )}
                        </S.AdjustHeader>
                        {modeTemplate ? null : (
                            <S.RowTitle style={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                {(listDescriptions || []).map((v, i) => (
                                    <Button key={v} style={{ width: 100, height: 30, marginTop: 7, marginRight: 5 }} onPress={() => addNewDescription(i)} label={`Desc. ${i + 1}`} loading={loading} />
                                ))}
                            </S.RowTitle>
                        )}
                        {/* <S.Title style={{ marginTop: 20, marginBottom: 5}}>{`Descrição da publicação (${descriptionLength}/2200)`}</S.Title> */}
                        {/* <S.TextDefault
                            fontSize={16}
                            fontFamily="600"
                            color={textCarColor}
                        >
                            {`Descrição da publicação (${descriptionLength}/2200)`}
                        </S.TextDefault> */}
                        <Tooltip
                            isVisible={toolTipVisible}
                            content={
                                <Text>Edite sua descrição para se encaixar no número máximo de caracteres permitidos</Text>
                            }
                            contentStyle={{ height: 50, padding: 5, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}
                            placement={width <= 600 ? 'top' : 'right'}
                            onClose={() => setToolTipVisible(false)}
                            arrowStyle={{ backgroundColor: '#d5d5d5' }}
                            tooltipStyle={{ backgroundColor: 'white' }}
                        >
                            <S.BoxArea
                                placeholder="Descrição da publicação"
                                value={description}
                                multiline
                                numberOfLine={3}
                                ref={(ref) => !boxAreaRef ? boxAreaRef = ref : boxAreaRef = boxAreaRef}
                                // autoFocus
                                height={heightBoxArea}
                                selection={positionActualKeyboard}
                                onSelectionChange={getCurrentPositionActualKeyboard}
                                onChangeText={onChangeTextDescription}
                            />
                        </Tooltip>
                        {descriptionLength > 2200 ? (
                            <S.TextDefault
                                fontSize={18}
                                fontFamily="600"
                                color="red"
                            >
                                {`Limite máximo de 2200 caracteres alcançado.`}
                            </S.TextDefault>
                        ) : null}
                        <S.TextDefault
                            fontSize={18}
                            fontFamily="600"
                            color="gray"
                        >
                            {`Contagem atual: ${descriptionLength}`}
                        </S.TextDefault>
                        {heightBoxArea === 300 ? (
                            <S.ResizeBoxArea onPress={() => setHeightBoxArea('100%')}>
                                <MaterialCommunityIcons name="arrow-down-drop-circle" size={18} color="white" />
                            </S.ResizeBoxArea>
                        ) : (
                            <S.ResizeBoxArea onPress={() => setHeightBoxArea(300)}>
                                <MaterialCommunityIcons name="arrow-up-drop-circle" size={18} color="white" />
                            </S.ResizeBoxArea>
                        )}
                    </S.AdjustBoxTag>
                    {width <= 600 ? renderEmoji() : null}
                    {modeTemplate ? null : (
                        <S.AdjustBoxTag width={width}>
                            <S.TextDefault
                                fontSize={16}
                                fontFamily="600"
                                color={textCarColor}
                            >
                                Clique nas tags abaixo para inserir na caixa de texto lado:
                            </S.TextDefault>
                            {!allTags ? null : (
                                <S.InputPickerContainer>
                                    {!allTags.headers[1]?.id ? null : (
                                        <S.FlowTags>
                                            <S.TouchableOpacityEmpty
                                                onPress={() => addDataToInput(allTags.headers[1]?.id, allTags.headers[1]?.name)}
                                                focus={tagsSelected.includes(allTags.headers[1]?.name)}
                                            >
                                                <S.Tag>
                                                    {allTags.headers[1]?.name}
                                                </S.Tag>
                                            </S.TouchableOpacityEmpty>
                                        </S.FlowTags>
                                    )}
                                    <S.TextDefault
                                        fontSize={15}
                                        fontFamily="500"
                                        color={fontBlack}
                                        margin={2}
                                    >
                                        Veículo
                                    </S.TextDefault>
                                    <S.FlowTags>
                                        {allTags.vehicle.map((v) => (
                                            <S.TouchableOpacityEmpty
                                                onPress={() => addDataToInput(v.id === true ? v.name : v.id, v.name, v.name)}
                                                focus={tagsSelected.includes(v.name)}
                                            >
                                                <S.Tag>
                                                    {v.name}
                                                </S.Tag>
                                            </S.TouchableOpacityEmpty>
                                        ))}
                                    </S.FlowTags>
                                    <S.TextDefault
                                        fontSize={15}
                                        fontFamily="500"
                                        color={fontBlack}
                                        margin={2}
                                    >
                                        Vendedor
                                    </S.TextDefault>
                                    <S.FlowTags>
                                        {allTags.sellers.map((v) => (
                                            <S.TouchableOpacityEmpty
                                                onPress={() => addDataToInput(v.id === true ? v.name : v.id, v.name)}
                                                focus={tagsSelected.includes(v.name)}
                                            >
                                                <S.Tag>
                                                    {v.name}
                                                </S.Tag>
                                            </S.TouchableOpacityEmpty>
                                        ))}
                                    </S.FlowTags>
                                    <S.TextDefault
                                        fontSize={15}
                                        fontFamily="500"
                                        color={fontBlack}
                                        margin={2}
                                    >
                                        Valores e Financiamentos
                                    </S.TextDefault>
                                    <S.FlowTags>
                                        {allTags.values.map((v) => (
                                            <S.TouchableOpacityEmpty
                                                onPress={() => addDataToInput(v.id === true ? v.name : v.id, v.name)}
                                                focus={tagsSelected.includes(v.name)}
                                            >
                                                <S.Tag>
                                                    {v.name}
                                                </S.Tag>
                                            </S.TouchableOpacityEmpty>
                                        ))}
                                    </S.FlowTags>
                                    <S.TextDefault
                                        fontSize={15}
                                        fontFamily="500"
                                        color={fontBlack}
                                        margin={2}
                                    >
                                        Informações adicionais
                                    </S.TextDefault>
                                    <S.FlowTags>
                                        {allTags.info.map((v) => (
                                            <S.TouchableOpacityEmpty
                                                onPress={() => addDataToInput(v.id === true ? v.name : v.id, v.name)}
                                                focus={tagsSelected.includes(v.name)}
                                            >
                                                <S.Tag>
                                                    {v.name}
                                                </S.Tag>
                                            </S.TouchableOpacityEmpty>
                                        ))}
                                    </S.FlowTags>
                                    {!(allTags?.optionals || []).length ? null : (
                                        <>
                                            <S.TextDefault
                                                fontSize={15}
                                                fontFamily="500"
                                                color={fontBlack}
                                                margin={2}
                                            >
                                                Opcionais
                                            </S.TextDefault>
                                            <S.FlowTags>
                                                {[{ id: 0, name: 'Selecionar todos opcionais', allName: allTags.optionals.map((op) => op.name).join(', ') }, { id: 'all-list', name: 'Todos os opcionais lista', allName: allTags.optionals.map((op) => op.name).join(',').replaceAll(',', '\n') }, ...allTags.optionals].map((v) => (
                                                    <S.TouchableOpacityEmpty
                                                        onPress={() => {
                                                            if (v.id === 0 || v.id === 'all-list') {
                                                                return addDataToInput(v.allName, v.id)
                                                            }
                                                            return addDataToInput(v.id, v.name)
                                                        }}
                                                        focus={tagsSelected.includes(v.name)}
                                                    >
                                                        <S.Tag>
                                                            {v.name}
                                                        </S.Tag>
                                                    </S.TouchableOpacityEmpty>
                                                ))}
                                            </S.FlowTags>
                                        </>
                                    )}
                                </S.InputPickerContainer>
                            )}
                        </S.AdjustBoxTag>
                    )}
                </S.AdjustBox>
            </S.TemplateInfoArea>
        )
    }

    function buttonConditionSave() {
        if (direct) {
            return !photosForTemplateSelected.length;
        }
        return !selectedTemplateOption?.id && !id_vehicle;
    }

    function buttonCondition() {
        if (!is_vehicle) {
            return false;
        }
        return direct
            ? !photosForTemplateSelected.length
            : !selectedTemplateOption?.id && !id_vehicle;
    }

    function changeModalVisible() {
        setOpenGallery(!openGallery);
        setRenderFrame(2);
        setPhotosForTemplateLocal(photosForTemplateSelectedAll);
        setPhotosForTemplateSelected(photosForTemplateSelectedAll);
        setExistVehicle(true);
        const modelAndVersion = `${photosForTemplate?.model || ''} ${photosForTemplate?.version || ''}`;
        const newTitle = photosForTemplate?.title ? photosForTemplate?.title?.substring(0, 35) : `${modelAndVersion?.substring(0, 35) || ''}`;
        setTitleVehicleEdit(newTitle);
        setTitleVehicleEditSave(newTitle);
        if (typeMethod !== 'publishStock' && dimensiones !== null) {
            setOpenCrop(true);
        }
        if (uriPhoto && !direct) {
            setTimeout(() => {
                ref.current?.scrollTo({
                    y: 0,
                    animated: true,
                });
            }, 500);
        }
        if (typeMethod !== 'publishStock') {
            setTimeout(() => {
                generatorTemplate(photosForTemplateSelectedAll, undefined, newTitle);
            }, 500);
        }
    }

    const [uriFrame, setUriFrame] = useState('');

    function renderStepFrame() {
        if (renderFrame === 3) {
            return (
                <SelectFrames
                    list={typeMethod === 'publishStock' ? photosForTemplateLocalUpdate : photosForTemplateSelected}
                    frames={allFrames}
                    imagesWithFrameSelected={imagesWithFrameSelected}
                    setImagesWithFrameSelected={setImagesWithFrameSelected}
                    framesSelecteds={framesSelecteds}
                    setFramesSelecteds={setFramesSelecteds}
                    frameActual={frameActual}
                    setFrameActual={setFrameActual}
                    loading={loadingApplyFrame}
                    isFrame={typeArt === 'molduras'}
                    textsForFrame={textsForFrame}
                    setTextsForFrame={setTextsForFrame}
                    handleClickOpenCrop={handleClickOpenCrop}
                    inputsInitialFrame={inputsInitialFrame}
                    uriFrame={uriFrame}
                    setUriFrame={setUriFrame}
                    currentTabPosition={currentTabPosition}
                    setCurrentTabPosition={setCurrentTabPosition}
                    setIsRemoveBgCapaOrMoldura={setIsRemoveBgCapaOrMoldura}
                    framesOrCapeRemoveBg={framesOrCapeRemoveBg}
                    setFramesOrCapeRemoveBg={setFramesOrCapeRemoveBg}
                    applyFrame={applyFrame}
                    renderInputVehicle={renderInputVehicle}
                />
            );
        }

        if (typeMethod === 'publishStock') {
            return (
                <S.TemplateImageAreaPublishStock style={{ height: direct ? 'auto' : 500 }}>
                    {direct ? RenderImageCar() : RenderImageArea()}
                    {/* {!loadingImage ? null : (
                        <S.ContainerLogos width={width}>
                            {renderLogos()}
                        </S.ContainerLogos>
                    )} */}
                </S.TemplateImageAreaPublishStock>
            );
        }

        return (
            <>
                <S.TemplateImageArea width={width}>
                    {RenderImageArea()}
                    {/* {direct ? RenderImageCar() : RenderImageArea()} */}
                    {!loadingImage ? null : (
                        <>
                            {width < 600 ? (
                                <S.ContainerLogosScroll showsHorizontalScrollIndicator horizontal width={width}>
                                    {renderLogos()}
                                </S.ContainerLogosScroll>
                            ) : (
                                <S.ContainerLogosScrollVertical
                                    contentContainerStyle={{
                                        flex: 1,
                                    }}
                                    style={{ maxWidth: '40%', width: '40%' }}
                                    width={width}
                                >
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            flex: 1,
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {renderLogos()}
                                    </View>
                                </S.ContainerLogosScrollVertical>
                            )}
                        </>
                    )}
                </S.TemplateImageArea>
                {renderWhatsappOrInstaInfos()}
            </>
        );
    }

    function resetPhotoVehicle() {
        setPreviewFrame([]);
        setSelectedTemplateOption({ thumb: '', id: null });
        if (renderFrame === 3) {
            const getOnlyBasePhoto = photosForTemplateSelected.map((v) => ({
                ...v,
                photo: v.photo_base,
            }));
            setPhotosForTemplateSelected(getOnlyBasePhoto);
        }
        setImagesWithFrameSelected([]);
        setFramesSelecteds('');
        setTypeArt('');
        setExistType(false);
        setRenderFrame(renderFrame === 3 ? 2 : 1);
    }

    const renderInputVehicle = () => {
        if (!(vehiclesList?.list || []).length || !is_vehicle && id) {
            return null;
        }
        return (
            <S.Column>
                <S.TextDefault fontSize={16}>Edite e confirme o título</S.TextDefault>
                <S.SearchInputArea style={{ marginLeft: 0, marginBottom: 0, width: '100%' }}>
                    <SearchIcon stroke={textCarColor} />
                    <S.SearchInput
                        placeholder="Título do veículo"
                        value={titleVehicleEdit || ''}
                        onChangeText={(txt) => {
                            // if (txt.length === 0) {
                            //     return setTitleVehicleEdit(titleVehicleEditSave);
                            // }
                            if (txt.length <= 35 || (txt.length > 35 && txt.length < (titleVehicleEdit || '').length)) {
                                return setTitleVehicleEdit(txt);
                            }
                            return null;
                        }}
                        onBlur={() => {
                            if (!titleVehicleEdit.length) {
                                return setTitleVehicleEdit(titleVehicleEditSave);
                            }
                        }}
                        // maxLength={35}
                    />
                </S.SearchInputArea>
            </S.Column>
        );
    };

    async function changeStepFrameImage(type?: string) {
        if (typeof type === 'string') {
            const { status, message, result } = await getUsedArtCodition(userInfo?.user?.id);

            if (status === 200) {
                setTypeArt(type);
                setRenderFrame(3);
                if (type === 'capa') {
                    const getOnlyId = photosForTemplateLocal.map((v) => v.id);
                    const format = allVehiclePhotos.filter((v) => !getOnlyId.includes(v.id));
                    const applyModified = photosForTemplateLocal.map((v) => ({
                        ...v,
                        modified: true,
                    }));
                    setPhotosForTemplateLocalUpdate([...applyModified, ...format]);
                } else {
                    const applyModified = photosForTemplateLocal.map((v) => ({
                        ...v,
                        modified: true,
                    }));
                    setPhotosForTemplateLocalUpdate(applyModified);
                }
            } else {
                const contactModalInfo: ContactModalProps = {
                    description: message,
                }
                setContactModalVisible(true);
                setContactModalInfo(contactModalInfo);
            }
        } else {
            setExistType(true);
            setRenderFrame(2);
            const removeId = photosForTemplateLocalUpdate.filter((v) => v.modified);
            setPhotosForTemplateLocal(removeId);
            setImagesWithFrameSelected([]);
            setFramesSelecteds('');
        }
    }

    async function applyDownloadPhotoSelected() {
        const { status, message, result } = await getUsedPublicationCodition(userInfo?.user?.id, 'true');
        if (status === 200) {
            const allPhotos = (typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected).map((v) => v.photo);
            const resultComputed = await computedArtsDownload(userInfo?.user?.id, allPhotos);
            if (resultComputed.status === 201) {
                getDownloadRemoteFile(allPhotos, setLoadingDownload);
            } else {
                const contactModalInfo: ContactModalProps = {
                    description: resultComputed.message,
                }
                setContactModalVisible(true);
                setContactModalInfo(contactModalInfo);
            }
        } else {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setContactModalVisible(true);
            setContactModalInfo(contactModalInfo);
        }
    }

    async function downloadPreviewThumbPub() {
        const { status, message, result } = await getUsedPublicationCodition(userInfo?.user?.id, 'true');
        if (status === 200) {
            const source = uriPhoto?.uri || uriPhoto;
            const uri = direct ? photosForTemplateSelected[0]?.photo : source;
            const resultComputed = await computedArtsDownload(userInfo?.user?.id, [uri]);
            if (resultComputed.status === 201) {
                getDownloadRemoteFile([uri], setLoadingDownload);
            } else {
                const contactModalInfo: ContactModalProps = {
                    description: resultComputed.message,
                }
                setContactModalVisible(true);
                setContactModalInfo(contactModalInfo);
            }
        } else {
            const contactModalInfo: ContactModalProps = {
                description: message,
            }
            setContactModalVisible(true);
            setContactModalInfo(contactModalInfo);
        }
    }

    function renderWhatsappOrInstaInfos() {

        function renderDescriptionInput() {
            if (props.modalInfo.is_birthday_message_default) {
                return null;
            }

            return (
                <>
                    <S.HeaderInputMessage>
                        <S.HeaderInputText>Mensagem</S.HeaderInputText>
                        <S.HeaderInputMessage>
                            <S.HeaderInputText>
                                Usar texto padrão
                            </S.HeaderInputText>
                            <Switch
                                trackColor={{
                                    false: '#767577',
                                    true: theme.colors.purple[500],
                                }}
                                thumbColor="#f4f3f4"
                                ios_backgroundColor="#3e3e3e"
                                onValueChange={toggleSwitchWhatsapp}
                                value={textDefaultUser}
                                style={{ marginTop: 5, marginLeft: 5 }}
                            />
                        </S.HeaderInputMessage>
                    </S.HeaderInputMessage>
                    <S.InputDescriptionWhatsapp
                        placeholder="Mensagem"
                        multiline
                        maxLength={150}
                        value={whatsappDescription}
                        onChangeText={setWhatsappDescription}
                        disabled={textDefaultUser}
                    />
                    <S.FooterInputMessage>
                        <S.HeaderInputText>
                            Sua mensagem pode conter até 150 caracteres
                        </S.HeaderInputText>
                        <S.ButtonUpdate onPress={() => generatorTemplate()}>
                            <S.ButtonUpdateText>Atualizar</S.ButtonUpdateText>
                        </S.ButtonUpdate>
                    </S.FooterInputMessage>
                </>
            );
        }

        if (birthday) {
            const [dayBirthDay, monthBirthDay] = birthInfo?.birth_date?.split('/');
            const currentDate = new Date();
            const birthDayFormat = dayBirthDay && monthBirthDay ? `${dayBirthDay}/${monthBirthDay}/${currentDate.getFullYear()}` : '';
            return (
                <S.WhatsappContainer width={width}>
                    <S.WhatsappContainerTitle>
                        {birthInfo.name}
                    </S.WhatsappContainerTitle>
                    <View style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        {buttonDownloadImage()}
                    </View>
                    <S.WhatsappContainerDataContainer>
                        <S.WhatsappContainerLabel>
                            Data:{' '}
                            <S.WhatsappContainerDataText>
                                {birthDayFormat}
                            </S.WhatsappContainerDataText>{' '}
                        </S.WhatsappContainerLabel>
                        <S.WhatsappContainerLabel>
                            Idade:{' '}
                            <S.WhatsappContainerDataText>
                                {birthInfo.age}
                            </S.WhatsappContainerDataText>{' '}
                        </S.WhatsappContainerLabel>
                        <S.WhatsappContainerLabel>
                            Cidade:{' '}
                            <S.WhatsappContainerDataText>
                                {birthInfo.city}
                            </S.WhatsappContainerDataText>{' '}
                        </S.WhatsappContainerLabel>
                    </S.WhatsappContainerDataContainer>
                    {renderDescriptionInput()}
                </S.WhatsappContainer>
            );
        }

        return (
            <S.TemplateInfoArea width={width}>
                {(photosForTemplateSelected[0]?.photo && !direct) ||
                (uriPhoto && !direct) ? (
                    <>
                        <S.ContainerButtonsActions>
                            {is_removebg || is_overlapbg ? (
                                <S.ContainerButton>
                                    <S.Button
                                        width={width}
                                        onPress={handleModalRemoveBg}
                                        buttonColor={purpleFont}
                                        fillWidth={width < 480 ? 180 : 220}
                                        style={{ marginTop: 10, marginBottom: 10, width: width < 480 ? 180 : 220, marginRight: 0 }}>
                                        <S.TextDefault
                                            fontSize={width < 480 ? 15 : 18}
                                            fontFamily="regular"
                                            color={whiteFont}>
                                            Remover fundo avançado
                                        </S.TextDefault>
                                    </S.Button>
                                    <S.Label>Taxa de R$0,99 por imagem recortada.</S.Label>
                                </S.ContainerButton>
                            ) : null}
                            {/* <S.Button
                                width={width}
                                onPress={downloadPreviewThumbPub}
                                style={{ width: width < 480 ? (width < 430 ? (width < 400 ? (width < 360 ? (width < 340 ? 80 : 100) : 120) : 150) : 180) : 200, marginTop: 10, marginBottom: 0, marginLeft: width < 480 ? 2 : 20 }}>
                                <S.TextDefault
                                    fontSize={width < 400 ? (width < 360 ? (width < 340 ? 11 : 14) : 15) : 18}
                                    fontFamily="regular"
                                    color={purpleFont}>
                                    Baixar imagem
                                </S.TextDefault>
                            </S.Button> */}
                        </S.ContainerButtonsActions>
                        <S.RowTitle style={{ marginTop: 10, justifyContent: 'flex-start' }}>
                            {type === 'banner-site' ? null : (
                                <Button
                                    style={{ width: width < 400 ? 160 : 200, height: 40, marginRight: 10 }}
                                    onPress={() => {
                                        if (!(vehiclesList?.list || []).length || !is_vehicle && id) {
                                            LoadPhoto(false, false, true);
                                        } else {
                                            setOpenGallery(true);
                                            setTimeout(() => {
                                                LoadPhoto(true, true);
                                            }, 1000);
                                        }
                                    }}
                                    label="Carregar Fotos"
                                    loading={loading}
                                />
                            )}
                            {buttonDownloadImage()}
                        </S.RowTitle>
                    </>
                ) : null}
                {typeMethod !== 'publishStock' && titleVehicleEditSave?.length > 0 && is_input_title && (vehiclesList?.list || []).length > 0 ? (
                    <S.GridTitleVehicle style={{ marginTop: 10, marginBottom: 10 }}>
                        {renderInputVehicle()}
                        <S.ButtonUpdate
                            onPress={() => {
                                if (!titleVehicleEdit.length) {
                                    setTitleVehicleEdit(titleVehicleEditSave);
                                    generatorTemplate(undefined, undefined, titleVehicleEditSave);
                                } else {
                                    generatorTemplate();
                                }
                            }}
                            style={{ minHeight: 40, width: '100%' }}
                            disabled={!loadingImage}
                        >
                            {!loadingImage ? (
                                <S.LoadingImage size='small' color={Theme.colors.white} width={width} />
                            ) : (
                                <S.ButtonUpdateText>Confirmar</S.ButtonUpdateText>
                            )}
                        </S.ButtonUpdate>
                    </S.GridTitleVehicle>
                ) : null}
                <S.TextDefault
                    fontSize={25}
                    fontFamily="bold"
                    color={fontBlack}>
                    {title || ''}
                </S.TextDefault>
                {typeMethod !== 'publishStock' && props.modalInfo?.id && props.modalInfo?.options_text?.filter((text) => text)?.length > 0 && (is_vehicle ? photosForTemplateSelected?.length > 0 && !openGallery : true) ? (
                    <>
                        <S.ContentEdit width={width}>
                            {renderInputs(props.modalInfo)}
                        </S.ContentEdit>
                        <S.ButtonUpdate
                            onPress={() => {
                                if (!loadingApplyFrame) {
                                    generatorTemplate(photosForTemplateSelected);
                                }
                            }}
                            style={{ marginBottom: 10 }}
                        >
                            <S.ButtonUpdateText>Atualizar</S.ButtonUpdateText>
                        </S.ButtonUpdate>
                    </>
                ) : null}
                <S.RowTitle>
                    <S.TextDefault
                        fontSize={18}
                        fontFamily="bold"
                        color={theme.colors.gray[650]}
                    >
                        {is_vehicle || (!id && 'Meus veículos')}
                    </S.TextDefault>
                    {!is_vehicle && id ? null : (
                        <S.SearchInputArea style={{ marginLeft: 0, width: '100%' }}>
                            <SearchIcon stroke={textCarColor} />
                            <S.SearchInput
                                placeholder="Pesquisar"
                                value={searchText}
                                onChangeText={setSearchText}
                                style={{ color: theme.colors.gray[600] }}
                            />
                        </S.SearchInputArea>
                    )}
                </S.RowTitle>
                {!(vehiclesList?.list || []).length || !is_vehicle && id ? null : (
                    <S.CarListArea style={{ marginTop: 0 }}>
                        <S.AlignCarArea width={width} horizontal={isMobile} contentContainerStyle={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} style={{ height: width < 400 ? 300 : 380, maxHeight: width < 400 ? 300 : 380 }}>
                            {RenderCarList(false)}
                        </S.AlignCarArea>
                        {RenderBackIcon()}
                        {RenderNextIcon()}
                    </S.CarListArea>
                )}
            </S.TemplateInfoArea>
        );
    }

    function renderButtonsScheduling() {
        if (renderFrame === 3) {
            return null;
        }

        if (birthday) {
            const firstName = birthInfo?.name.split(' ')

            const body = {
                idArt: direct ? null : id,
                idUser: userInfo?.user?.id,
                message: whatsappDescription,
                idCustomer: birthInfo?.id,
                firstName: firstName[0],
                birthDay: birthInfo?.birth_date,
                logoSelected: imagesSelecteds[0]?.photo || '',
            }

            function publicWhatsapp() {
                publicateTemplate(body);
            }

            return (
                <S.ButtonArea width={width}>
                    <S.ButtonWhatsapp onPress={publicWhatsapp} disabled={!loadingImage}>
                        <S.ButtonWhatsappIcon />
                        <S.ButtonWhatsappText>Enviar</S.ButtonWhatsappText>
                    </S.ButtonWhatsapp>
                </S.ButtonArea>
            );
        }

        return (
            <S.ButtonArea width={width}>
                {/* <S.Button style={{ width: width <= 900 ? '45%' : 198}} marginRight={marginRight} width={width} onPress={handlePublicAfterPress}>
                    <S.TextDefault
                        fontSize={18}
                        fontFamily="regular"
                        color={purpleFont}>
                        Finalizar depois
                    </S.TextDefault>
                </S.Button> */}
                <S.PublicationButtonArea width={width} style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: width <= 600 ? 'center' : 'flex-start' }}>
                    {/* <S.Button style={{ width: width <= 900 ? '45%' : 198, opacity: descriptionLength > 2200 ? 0.5 : 1 }} marginRight={width <= 900 ? 5 : 16} width={width} onPress={handlePublicAfterPress}>
                        <S.TextDefault
                            fontSize={width <= 450 ? (width <= 323 ? 12 : 14 ) : 18}
                            fontFamily="regular"
                            color={purpleFont}
                        >
                            Finalizar depois
                        </S.TextDefault>
                    </S.Button> */}
                    <S.Button
                        width={width}
                        buttonColor={Theme.colors.green[50]}
                        borderColor={Theme.colors.green[50]}
                        // disabled={buttonConditionSave()}
                        style={{ borderColor: Theme.colors.purple[500], borderWidth: 1, width: width <= 900 ? '45%' : 198, opacity: descriptionLength > 2200 ? 0.5 : 1}}
                        marginRight={width <= 900 ? (width <= 600 ? 0 : 5) : 16}
                        onPress={() => {
                            if (type !== 'banner-site') {
                                setIsPublishSite(true);
                                setModalInfoSend(true);
                                return null;
                            }
                            return confirmSocialPress('banner-site');
                        }}
                    >
                        <S.TextDefault
                            fontSize={width <= 450 ? (width <= 323 ? 12 : 14 ) : 18}
                            fontFamily="regular"
                            color={Theme.colors.purple[500]}
                        >
                            Publicar no meu site
                        </S.TextDefault>
                    </S.Button>
                    {type === 'banner-site' ? null : (
                        <>
                            <S.Button
                                width={width}
                                buttonColor={coditionBackgroundButton}
                                borderColor={coditionBackgroundButton}
                                disabled={buttonConditionSave()}
                                style={{ width: width <= 900 ? '45%' : 198, opacity: descriptionLength > 2200 ? 0.5 : 1}}
                                marginRight={width <= 900 ? (width <= 600 ? 0 : 5) : 16}
                                onPress={handleFinishPress}
                            >
                                <S.TextDefault
                                    fontSize={width <= 450 ? (width <= 323 ? 12 : 14 ) : 18}
                                    fontFamily="regular"
                                    color={whiteFont}>
                                    Salvar
                                </S.TextDefault>
                            </S.Button>
                            <S.Button
                                width={width}
                                buttonColor={coditionBackgroundButton}
                                borderColor={coditionBackgroundButton}
                                disabled={buttonCondition()}
                                marginRight={width <= 900 ? 5 : 16}
                                style={{ width: width <= 900 ? '45%' : 198, opacity: descriptionLength > 2200 ? 0.5 : 1 }}
                                onPress={handlePublicNowPress}>
                                <S.TextDefault
                                    fontSize={width <= 450 ? (width <= 323 ? 12 : 14 ) : 18}
                                    fontFamily="regular"
                                    color={whiteFont}>
                                    Publicar agora
                                </S.TextDefault>
                            </S.Button>
                            <S.Button
                                disabled={buttonCondition()}
                                width={width}
                                style={{ width: width <= 900 ? '45%' : 198, opacity: descriptionLength > 2200 ? 0.5 : 1 }}
                                onPress={schedulingButtonPress}>
                                <S.TextDefault
                                    fontSize={width <= 450 ? (width <= 323 ? 12 : 14 ) : 18}
                                    fontFamily="regular"
                                    color={purpleFont}>
                                    Agendar publicação
                                </S.TextDefault>
                            </S.Button>
                        </>
                    )}
                </S.PublicationButtonArea>
            </S.ButtonArea>
        );
    }

    function renderFixedAspectRatioLeft(stepCropOverlay?: number) {
        let dimensionesFrameOrCape = typeMethod === 'publishStock' ? allFrames.find((v) => v.id === framesSelecteds)?.dimensiones : null;
        const defaultAspectRatioLeft = 1;
        if (dimensiones?.length || dimensionesFrameOrCape?.length) {
            const formatDimensiones = typeMethod === 'publishStock' ? dimensionesFrameOrCape : dimensiones;
            if (formatDimensiones[typeArt === 'molduras' ? 0 : stepCropOverlay || stepCrop]?.length > 0) {
                return formatDimensiones[typeArt === 'molduras' ? 0 : stepCropOverlay || stepCrop].split(':')[0] || 1;
            }
            return defaultAspectRatioLeft;
        }
        return defaultAspectRatioLeft;
    }

    function renderFixedAspectRatioRight(stepCropOverlay?: number) {
        let dimensionesFrameOrCape = typeMethod === 'publishStock' ? allFrames.find((v) => v.id === framesSelecteds)?.dimensiones : null;
        const defaultAspectRatioRight = 1;
        if (dimensiones?.length || dimensionesFrameOrCape?.length) {
            const formatDimensiones = typeMethod === 'publishStock' ? dimensionesFrameOrCape : dimensiones;
            if (formatDimensiones[typeArt === 'molduras' ? 0 : stepCropOverlay || stepCrop]?.length > 1) {
                return formatDimensiones[typeArt === 'molduras' ? 0 : stepCropOverlay || stepCrop].split(':')[1] || 1;
            }
            return defaultAspectRatioRight;
        }
        return defaultAspectRatioRight;
    }

    const applyRemoveBg = () => {
        const updateImagesWithFrameSelected = (typeMethod === 'publishStock' ? imagesWithFrameSelected : photosForTemplateSelected).map((itemRemoveBg) => {
            if (itemRemoveBg.id === photosForTemplateSelected[0]?.id) {
                return {
                    ...itemRemoveBg,
                    removeBg: true,
                };
            }
            return itemRemoveBg;
        });
        if (typeMethod === 'publishStock') {
            setImagesWithFrameSelected(updateImagesWithFrameSelected);
            applyFrameCrop(updateImagesWithFrameSelected, framesSelecteds, photoRemoveBgActual);
        } else {
            setPhotosForTemplateSelected(updateImagesWithFrameSelected);
            generatorTemplate(updateImagesWithFrameSelected, false);
        }
    };

    function renderInputs(item: any) {
        let textsForFrameUpdate: Array<number> = [];
        if ((item?.options_text || [])?.includes('1')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 1];
        }
        if ((item?.options_text || [])?.includes('2')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 2];
        }
        if ((item?.options_text || [])?.includes('3')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 3];
        }
        if ((item?.options_text || [])?.includes('4')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 4];
        }
        if ((item?.options_text || [])?.includes('5')) {
            textsForFrameUpdate = [...textsForFrameUpdate, 5];
        }
        if (!textsForFrameUpdate.length) {
            return null;
        }
        return textsForFrame.filter((v) => textsForFrameUpdate.includes(v.id)).map((v, index) => {
            const onChangeText = (txt) => {
                const updateText = textsForFrame.map((e) => {
                    if (e.id === v.id) {
                        return {
                            ...e,
                            txt,
                        };
                    }
                    return e;
                });
                setTextsForFrame(updateText);
            };
            return (
                <View style={{ flexDirection: 'column', width: '100%' }}>
                    <S.TextFrameTexts>{item?.placeholder_text?.length > 0 ? item?.placeholder_text[v.id - 1] || '' : ''}</S.TextFrameTexts>
                    <S.SearchInputTexts
                        // placeholder={`Texto ${v.id}`}
                        placeholder=""
                        value={v.txt}
                        onChangeText={onChangeText}
                        key={v.id}
                        style={{ width: '100%', padding: 3 }}
                        multiline
                        numberOfLines={2}
                    />
                </View>
            );
        })
    }

    return (
        <S.ContainerModal isVisible={modalVisible}>
            <S.ScrollView ref={ref}>
                <S.ModalArea width={width}>
                    <Modal
                        isVisible={openCrop}
                        closeModal={() => {
                            setOpenCrop(false);
                            setPreviewFrame([]);
                            setImagesWithFrameSelectedCrop(null);
                        }}
                    >
                        <S.ContainerModalGallery>
                            <S.ScrollFilters style={{ padding: 40, width: '100%', height: '100%', maxHeight: '100%' }}>
                                <S.Title style={{ color: theme.colors.purple[500], fontSize: 28, fontFamily: theme.font.segoeUi.bold }}>Recortar Fotos</S.Title>
                                <S.ContainerHeader>
                                    <S.Title style={{ color: theme.colors.black, fontSize: 14, marginTop: 10 }}>{typeArt === 'molduras' ? 'Se desejar, clique sobre a foto e faça o recorte para encaixá-la na arte. Ou clique em confirmar para continuar sem alterar a foto.' : 'Se desejar, clique sobre a foto e faça o recorte para encaixá-la na arte. Ou clique em confirmar para continuar sem alterar a foto.'}</S.Title>
                                </S.ContainerHeader>
                                <S.AdjustHeader style={{ alignItems: 'flex-start', flexDirection: 'row', flex: 1 }}>
                                    <S.Column style={{ flexDirection: 'column', flex: 1, height: typeArt !== 'molduras' ? '100%' : 'auto' }}>
                                        {(typeArt === 'molduras' ? (typeMethod === 'publishStock' ? imagesWithFrameSelected : photosForTemplateSelected) : [1])?.map((item, i) => {
                                            const responseAspectRatioLeft = renderFixedAspectRatioLeft(i);
                                            // console.log('responseAspectRatioLeft', responseAspectRatioLeft);
                                            const responseAspectRatioRight = renderFixedAspectRatioRight(i);
                                            // console.log('responseAspectRatioRight', responseAspectRatioRight);
                                            // Aumentar width e height da imagem recortada mantendo a proporção até o limite de width e height da imagem original
                                            const responseDataImage = calculateAspectRatioFit(responseAspectRatioLeft, responseAspectRatioRight, width <= 1110 ? (width <= 600 ? (width <= 480 ? (width <= 350 ? 75 : 100) : 150) : 200) : 300, width <= 1110 ? (width <= 600 ? (width <= 480 ? (width <= 350 ? 75 : 100) : 150) : 200) : 300);
                                            // console.log('responseDataImage', responseDataImage);
                                            return (
                                                <S.ImageCardContainerView
                                                    key={item?.photo_base || item}
                                                    style={{
                                                        backgroundColor: '#e5e5e5',
                                                        borderTopLeftRadius: 5,
                                                        borderBottomLeftRadius: 5,
                                                        paddingLeft: width <= 880 ? 0 : 10,
                                                        flexDirection: width <= 880 ? 'column-reverse' : 'row-reverse',
                                                        alignItems: 'center',
                                                        paddingTop: width <= 880 ? 10 : 0,
                                                        justifyContent: 'flex-end',
                                                        height: typeArt !== 'molduras' ? '100%' : (width <= 1110 ? (width <= 600 ? ( width <= 480 ? 250 : 250) : 320) : 340)
                                                    }}
                                                >
                                                    {width <= 880 ? null : (
                                                        <S.Liner />
                                                    )}
                                                    {width <= 880 ? null : (
                                                        <S.Circle style={{ marginLeft: 10 }} />
                                                    )}
                                                    {typeArt === 'molduras' ? (
                                                        <TouchableOpacity
                                                            onPress={async () => {
                                                                if (frameActual?.dimensiones === null) {
                                                                    return null;
                                                                }
                                                                const bodyUpload = {
                                                                    urlRevenda: item?.photo_base,
                                                                    name: uuidv4(),
                                                                    type: `image/${item?.photo_base?.split('.')?.slice(-1)[0]}`
                                                                };
                                                                const { result } = await getImageLink(bodyUpload);
                                                                setImagesWithFrameSelectedCrop({
                                                                    ...item,
                                                                    photo_base: result,
                                                                });
                                                                setStepCrop(i);
                                                                setTimeout(() => {
                                                                    setIsVisibleImageEditor(true);
                                                                }, 300);
                                                            }}
                                                        >
                                                            <S.FrameCard
                                                                style={{
                                                                    marginLeft: width <= 880 ? 0 : 10,
                                                                    width: responseDataImage.width,
                                                                    height: responseDataImage.width
                                                                }}
                                                                source={{ uri: item?.photo }}
                                                                resizeMode='contain'
                                                                onContextMenu={(e) => e.preventDefault()}
                                                            />
                                                            {width > 880 || frameActual?.dimensiones === null ? null : (
                                                                <S.ContainerBorder style={{ marginBottom: width <= 880 ? 10 : 0, minHeight: 'auto', maxHeight: 'auto', height: 'auto', minWidth: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                    <S.Title style={{ color: theme.colors.black, fontSize: width <= 480 ? (width <= 350 ? 6 : 8) : 10, marginTop: 0, marginBottom: 0 }}>
                                                                        Clique sobre a foto para cortar
                                                                    </S.Title>
                                                                    <S.ContainerArrowUp width={width}>
                                                                        <S.IconArrow width={width} name="arrow-up" style={{ color: 'white' }} />
                                                                    </S.ContainerArrowUp>
                                                                </S.ContainerBorder>
                                                            )}
                                                        </TouchableOpacity>
                                                    ) : (
                                                        <View>
                                                            <S.Grid>
                                                                {(typeMethod === 'publishStock' ? imagesWithFrameSelected : photosForTemplateSelected).map((e, index) => (
                                                                    <TouchableOpacity
                                                                        onPress={async () => {
                                                                            if (frameActual?.dimensiones === null) {
                                                                                return null;
                                                                            }
                                                                            const bodyUpload = {
                                                                                urlRevenda: e?.photo_base,
                                                                                name: uuidv4(),
                                                                                type: `image/${e?.photo_base?.split('.')?.slice(-1)[0]}`
                                                                            };
                                                                            const { result } = await getImageLink(bodyUpload);
                                                                            setImagesWithFrameSelectedCrop({
                                                                                ...e,
                                                                                photo_base: result,
                                                                            });
                                                                            setStepCrop(index);
                                                                            setTimeout(() => {
                                                                                setIsVisibleImageEditor(true);
                                                                            }, 300);
                                                                        }}
                                                                        style={{ position: 'relative', flexDirection: 'column' }}
                                                                    >
                                                                        <S.FrameCard
                                                                            style={{
                                                                                width: width <= 1110 ? (width <= 600 ? ( width <= 480 ? 50 : 75) : 100) : 150,
                                                                                height: width <= 1110 ? (width <= 600 ? ( width <= 480 ? 50 : 75) : 100) : 150,
                                                                            }}
                                                                            source={{ uri: e?.photo }}
                                                                            resizeMode='contain'
                                                                            onContextMenu={(e) => e.preventDefault()}
                                                                        />
                                                                        <S.ContainerArrowIndex width={width}>
                                                                            {index + 1}
                                                                        </S.ContainerArrowIndex>
                                                                            {isRemoveBgCapaOrMoldura || (is_removebg && typeMethod !== 'publishStock') ? (
                                                                                <S.ContainerPurpleButton
                                                                                    onPress={() => {
                                                                                        setPhotoRemoveBgActual(item);
                                                                                        setModalEnabledRemoveBg(true);
                                                                                    }}
                                                                                    style={{ marginTop: 5, marginBottom: 5, minHeight: 50, minWidth: '100%' }}
                                                                                >
                                                                                    <S.TextFrame style={{ fontSize: width <= 1110 ? (width <= 600 ? 8 : 14) : 16 }}>
                                                                                        Remover fundo avançado
                                                                                    </S.TextFrame>
                                                                                </S.ContainerPurpleButton>
                                                                            ) : null}
                                                                    </TouchableOpacity>
                                                                ))}
                                                            </S.Grid>
                                                            {width > 880 || frameActual?.dimensiones === null ? null : (
                                                                <S.ContainerBorder style={{ marginBottom: width <= 880 ? 10 : 0, minHeight: 'auto', maxHeight: 'auto', height: 'auto', minWidth: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                    <S.Title style={{ color: theme.colors.black, fontSize: width <= 480 ? (width <= 350 ? 6 : 8) : 10, marginTop: 0, marginBottom: 0 }}>
                                                                        Clique sobre a foto para cortar
                                                                    </S.Title>
                                                                    <S.ContainerArrowUp width={width}>
                                                                        <S.IconArrow width={width} name="arrow-up" style={{ color: 'white' }} />
                                                                    </S.ContainerArrowUp>
                                                                </S.ContainerBorder>
                                                            )}
                                                        </View>
                                                    )}
                                                    <S.Column style={{ width: width <= 880 ? (width <= 600 ? ( width <= 480 ? 100 : 150) : 200) : 'auto', marginRight: typeArt === 'molduras' || width <= 880 ? 0 : 10 }}>
                                                        <S.AdjustButtons style={{ flexDirection: width <= 880 ? (width <= 480 ? 'column' : 'row') : 'column' }}>
                                                            <S.ContainerPurple style={{ alignSelf: width <= 880 ? 'flex-start' : 'center' , marginBottom: width <= 880 ? 10 : 0, minHeight: width <= 880 ? (width <= 600 ? 30 : 50) : 70, minWidth: width <= 880 ? 50 : 70 }}>
                                                                <S.TextFrame style={{ fontSize: width <= 1110 ? (width <= 600 ? (width <= 480 ? 10 : 14) : 16) : 20 }}>
                                                                    {`Foto${typeArt === 'molduras' ? ` ${i + 1}` : 's'}`}
                                                                </S.TextFrame>
                                                            </S.ContainerPurple>
                                                            {typeArt === 'molduras' && isRemoveBgCapaOrMoldura ? (
                                                                <S.ContainerPurpleButton
                                                                    onPress={() => {
                                                                        setPhotoRemoveBgActual(item);
                                                                        setModalEnabledRemoveBg(true);
                                                                    }}
                                                                    style={{ marginTop: width <= 880 ? 0 : 10, marginLeft: width <= 880 ? (width <= 480 ? 0 : 10) : 0, alignSelf: width <= 880 ? 'flex-start' : 'center' , marginBottom: width <= 880 ? 10 : 0, minHeight: width <= 880 ? (width <= 600 ? 30 : 50) : 70, maxHeight: width <= 880 ? (width <= 600 ? 30 : 50) : 70, minWidth: width <= 880 ? 50 : 70 }}
                                                                >
                                                                    <S.TextFrame style={{ fontSize: width <= 880 ? (width <= 600 ? 8 : 12) : 14 }}>
                                                                        Remover fundo avançado
                                                                    </S.TextFrame>
                                                                </S.ContainerPurpleButton>
                                                            ) : null}
                                                        </S.AdjustButtons>
                                                        {width <= 880 || frameActual?.dimensiones === null ? null : (
                                                            <S.ContainerBorder style={{ marginBottom: width <= 880 ? 10 : 0 }}>
                                                                <S.Title style={{ color: theme.colors.black, fontSize: width <= 880 ? 7 : 10, marginTop: 10 }}>
                                                                    Clique sobre a foto para cortar
                                                                </S.Title>
                                                                <S.ContainerArrow width={width}>
                                                                    <S.IconArrow width={width} name="arrow-right" style={{ color: 'white' }} />
                                                                </S.ContainerArrow>
                                                            </S.ContainerBorder>
                                                        )}
                                                    </S.Column>
                                                </S.ImageCardContainerView>
                                            );
                                        })}
                                    </S.Column>
                                    <S.Column style={{ flexDirection: 'column', flex: 1, height: typeArt !== 'molduras' ? '100%' : 'auto' }}>
                                        {(typeMethod === 'publishStock' ? previewFrame : [selectedTemplateOption])?.map((item, i) => {
                                            const responseAspectRatioLeft = renderFixedAspectRatioLeft(i);
                                            // console.log('responseAspectRatioLeft', responseAspectRatioLeft);
                                            const responseAspectRatioRight = renderFixedAspectRatioRight(i);
                                            // console.log('responseAspectRatioRight', responseAspectRatioRight);
                                            // Aumentar width e height da imagem recortada mantendo a proporção até o limite de width e height da imagem original
                                            const responseDataImage = calculateAspectRatioFit(responseAspectRatioLeft, responseAspectRatioRight, width <= 1110 ? (width <= 600 ? ( width <= 480 ? 100 : 150) : 200) : 300, width <= 1110 ? (width <= 600 ? ( width < 400 ? 100 : 150) : 200) : 300);
                                            // console.log('responseDataImage', responseDataImage);
                                            const sizeResult = typeArt === 'molduras' ? responseDataImage.width : width <= 1110 ? (width <= 600 ? ( width <= 480 ? 100 : 150) : 200) : 300;
                                            return (
                                                <S.ImageCardContainerView
                                                    style={{
                                                        backgroundColor: '#e5e5e5',
                                                        flexDirection: width <= 880 ? 'column-reverse' : 'row',
                                                        alignItems: 'center',
                                                        paddingTop: width <= 880 ? 10 : 0,
                                                        justifyContent: 'flex-end',
                                                        paddingRight: width <= 880 ? 0 : 10,
                                                        borderBottomRightRadius: 5,
                                                        borderTopRightRadius: 5,
                                                        height: typeArt !== 'molduras' ? '100%' : (width <= 1110 ? (width <= 600 ? ( width <= 480 ? 250 : 250) : 320) : 340)
                                                    }}
                                                    key={typeMethod === 'publishStock' ? item?.photo_base : item?.id}
                                                >
                                                    {width <= 880 ? null : (
                                                        <S.Liner />
                                                    )}
                                                    {width <= 880 ? null : (
                                                        <S.Circle style={{ marginRight: 10 }} />
                                                    )}
                                                    {(typeMethod !== 'publishStock' && !loadingImage) || (typeMethod === 'publishStock' && loadingApplyFrame) ? (
                                                        <S.LoadingImage size='large' color={Theme.colors.purple[500]} width={width} style={{ width: sizeResult, height: sizeResult }} />
                                                    ) : (
                                                        <S.FrameCard
                                                            style={{
                                                                backgroundColor: 'transparent',
                                                                marginRight: width <= 880 ? 0 : 10,
                                                                width: sizeResult,
                                                                height: sizeResult
                                                            }}
                                                            source={{ uri: typeMethod === 'publishStock' ? item?.photo : item?.thumb }}
                                                            resizeMode='contain'
                                                            onContextMenu={(e) => e.preventDefault()}
                                                        />
                                                    )}
                                                    {typeMethod === 'publishStock' ? (
                                                        <S.Column style={{ width: width <= 880 ? sizeResult : 'auto' }}>
                                                            <S.ContainerPurple style={{ alignSelf: width <= 880 ? 'flex-end' : 'center' , marginBottom: width <= 880 ? 10 : 0, minHeight: width <= 880 ? (width <= 600 ? 30 : 50) : 70, minWidth: width <= 880 ? 50 : 80 }}>
                                                                <S.TextFrame style={{ fontSize: width <= 1110 ? (width <= 600 ? 14 : 16) : 20 }}>
                                                                    {typeArt === 'molduras' ? 'Moldura' : 'Capa'}
                                                                </S.TextFrame>
                                                            </S.ContainerPurple>
                                                        </S.Column>
                                                    ) : null}
                                                </S.ImageCardContainerView>
                                            );
                                        })}
                                    </S.Column>
                                </S.AdjustHeader>
                            </S.ScrollFilters>
                            <S.AdjustButtonEnd>
                                <S.ButtonModalArea
                                    background="red"
                                    style={{ marginRight: 10, marginTop: 10 }}
                                    onPress={() => {
                                        setOpenCrop(false);
                                        setPreviewFrame([]);
                                        setImagesWithFrameSelectedCrop(null);
                                    }}
                                >
                                    <S.ButtonModalText>
                                        Cancelar
                                    </S.ButtonModalText>
                                </S.ButtonModalArea>
                                <S.ButtonModalArea
                                    background="purple"
                                    onPress={() => {
                                        setOpenCrop(false);
                                        setPreviewFrame([]);
                                        setImagesWithFrameSelectedCrop(null);
                                        if (typeMethod === 'publishStock') {
                                            applyFrame(imagesWithFrameSelected, undefined, false);
                                        }
                                    }}
                                    disabled={loadingApplyFrame}
                                    style={{ marginRight: 20 }}
                                >
                                    {loadingApplyFrame ? (
                                        <ActivityIndicator color={theme.colors.white} size={20} />
                                    ) : (
                                        <S.ButtonModalText>
                                            Confirmar
                                        </S.ButtonModalText>
                                    )}
                                </S.ButtonModalArea>
                            </S.AdjustButtonEnd>
                            <ImageEditor
                                imageUri={imagesWithFrameSelectedCrop?.photo_base || ''}
                                // imageUri="https://midiacarros.sfo3.digitaloceanspaces.com/MidiaCarros/531e8ede-466e-4c21-828a-844589759d2b.jpg"
                                // fixedAspectRatio={1 / 1}
                                fixedAspectRatio={renderFixedAspectRatioLeft() / renderFixedAspectRatioRight()}
                                isVisible={isVisibleImageEditor}
                                minimumCropDimensions={{
                                    width: 50,
                                    height: 50,
                                }}
                                editorOptions={{
                                    controlBar: {
                                        cancelButton: {
                                            text: 'Cancelar',
                                            iconName: 'close',
                                            color: 'white',
                                        },
                                        cropButton: {
                                            text: 'Recortar',
                                            iconName: 'crop',
                                            color: 'white',
                                        },
                                        backButton: {
                                            text: 'Voltar',
                                            iconName: 'arrow-back-ios',
                                            color: 'white',
                                        },
                                        saveButton: {
                                            text: 'Salvar',
                                            iconName: 'save',
                                            color: 'white',
                                        },
                                    },
                                }}
                                onEditingCancel={() => {
                                    setIsVisibleImageEditor(false);
                                }}
                                onEditingComplete={async (image) => {
                                    setLoadingCrop(true);
                                    const type = image.uri.split(/[:;]/).map(str => str.trim())
                                    const body = {
                                        name: imagesWithFrameSelectedCrop?.id,
                                        type: type[1] || 'image/jpeg',
                                        base64: image.uri,
                                    };
                                    // Upload da imagem recortada
                                    const responseImageLink = await getImageLink(body);
                                    // Pegar width e height da imagem original
                                    const responseDataImageOriginal = await getImageDimensions(imagesWithFrameSelectedCrop?.photo_base);
                                    // Pegar width e height da imagem recortada
                                    const responseDataImageCrop = await getImageDimensions(responseImageLink.result);
                                    // Aumentar width e height da imagem recortada mantendo a proporção até o limite de width e height da imagem original
                                    const responseDataImage = calculateAspectRatioFit(responseDataImageCrop.width, responseDataImageCrop.height, responseDataImageOriginal.width, responseDataImageOriginal.height);
                                    // Dimensionar a imagem cortada com width e height da imagem original
                                    const { uri } = await manipulateAsync(
                                        responseImageLink.result,
                                        [{ resize: { width: responseDataImage.width, height: responseDataImage.height } }],
                                        { format: (type[1] || 'image/jpeg').split('/')[1] || SaveFormat.JPEG, compress: 1 }
                                    );
                                    const bodyCrop = {
                                        name: imagesWithFrameSelectedCrop?.id,
                                        type: type[1] || 'image/jpeg',
                                        base64: uri,
                                    };
                                    // Upload da imagem resultante do dimensionamento
                                    const { result } = await getImageLink(bodyCrop);
                                    setLoadingCrop(false);
                                    if (typeMethod === 'publishStock') {
                                        const updateImagesWithFrameSelected = imagesWithFrameSelected.map((v) => {
                                            if (v.id === imagesWithFrameSelectedCrop?.id) {
                                                return {
                                                    ...v,
                                                    photo: result,
                                                    // photo_base: result,
                                                };
                                            }
                                            return v;
                                        });
                                        setImagesWithFrameSelected(updateImagesWithFrameSelected);
                                        setIsVisibleImageEditor(false);
                                        applyFrameCrop(updateImagesWithFrameSelected, framesSelecteds);
                                    } else {
                                        const updatePhotosForTemplateSelected = photosForTemplateSelected.map((v) => {
                                            if (v.id === imagesWithFrameSelectedCrop?.id) {
                                                return {
                                                    ...v,
                                                    photo: result,
                                                    // photo_base: result,
                                                };
                                            }
                                            return v;
                                        });
                                        setPhotosForTemplateSelected(updatePhotosForTemplateSelected);
                                        setIsVisibleImageEditor(false);
                                        generatorTemplate(updatePhotosForTemplateSelected);
                                    }
                                }}
                            />
                            <LoadingModal
                                visible={loadingCrop}
                                titleLoading='Salvando imagem...'
                            />
                        </S.ContainerModalGallery>
                    </Modal>
                    {/* <View style={{ position: 'absolute', top: 20, right: 20, zIndex: 100000 }}>
                        <Button
                            style={{ width: width < 400 ? 160 : 200, height: width < 400 ? 40 : 50 }}
                            onPress={() => {
                                setIsOrder(!isOrder);
                            }}
                            label={isOrder ? 'Ordenando imagens...' : 'Ordenar imagens'}
                            loading={loading}
                        />
                    </View> */}
                    <S.HeaderArea width={width}>
                        <S.GoBackArea>
                            <S.GoBackButton onPress={handleBackdropPress}>
                                <ArrowBack color={fontBlack} />
                            </S.GoBackButton>
                            <S.TextDefault
                                fontSize={25}
                                fontFamily="bold"
                                color={fontBlack}
                            >
                                Voltar
                            </S.TextDefault>
                        </S.GoBackArea>
                        <S.CloseButton onPress={resetAll}>
                            <S.QuitIcon resizeMode='contain' source={QuitIcon} onContextMenu={(e) => e.preventDefault()} />
                        </S.CloseButton>
                    </S.HeaderArea>
                    {typeMethod === 'publishStock' && renderFrame === 1 ? (
                        <>
                            <S.RowTitle style={{ justifyContent: width <= 622 ? 'center' : 'space-between', flexWrap: 'wrap', marginBottom: 10 }}>
                            <S.ColumnTitle width={width}>
                                <S.Title>Publique diretamente do estoque, aplique molduras e carimbos em suas fotos.</S.Title>
                                <S.Description>{'Para publicar um veículo diretamente do seu estoque, basta selecioná-lo clicando sobre a foto\nVocê também poderá, selecionar um veículo e aplicar uma moldura ou carimbo sobre as fotos. E também poderá fazer upload de fotos do seu dispositivo para carimbar, emoldurar e publicá-las.'}</S.Description>
                            </S.ColumnTitle>
                            <Button style={{ width: 200 }} onPress={() => LoadPhoto(false)} label="Carregar Fotos" loading={loading} />
                            </S.RowTitle>
                            {width < 400 ? <S.Divider /> : null}
                        </>
                    ) : null}
                    {(renderFrame === 1 && typeMethod === 'publishStock') || (renderFrame === 1 && dimensiones !== null && typeMethod !== 'publishStock') ? (
                        <>
                            <S.RowTitle style={{ alignItems: 'center', flexWrap: 'wrap', padding: width < 400 ? '10px' : '0px', borderRadius: width < 400 ? '6px' : '0px', flexDirection: width < 400 ? 'column' : 'row', backgroundColor: width < 400 ? theme.colors.purple[500] : 'white'}}>
                                <S.TextDefault
                                    fontSize={18}
                                    fontFamily="bold"
                                    color={width < 400 ? 'white' : theme.colors.gray[650]}
                                >
                                    {is_vehicle || (!id && 'Meus veículos')}
                                </S.TextDefault>
                                <S.SearchInputAreaPublishStock style={{ backgroundColor: 'white', width: width < 400 ? '100%' : '40%', marginTop: width < 400 ? 10 : 0 }}>
                                    <SearchIcon stroke={textCarColor} />
                                    <S.SearchInputPublishStock
                                        placeholder="Pesquisar"
                                        value={searchText}
                                        onChangeText={setSearchText}
                                    />
                                </S.SearchInputAreaPublishStock>
                            </S.RowTitle>
                            <S.CarListArea>
                                {width < 400 ? (
                                    <S.AlignCarAreaNotScroll width={width}>
                                        {RenderCarList(false)}
                                    </S.AlignCarAreaNotScroll>
                                ) : (
                                    <S.AlignCarArea
                                        width={width}
                                        // scrollEnabled={false}
                                        contentContainerStyle={{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            alignItems: 'center',
                                            justifyContent: width < 590 ? 'center' : 'flex-start',
                                        }}
                                    >
                                        {RenderCarList(false)}
                                    </S.AlignCarArea>
                                )}
                                {RenderBackIcon()}
                                {RenderNextIcon()}
                            </S.CarListArea>
                        </>
                    ) : null}
                    {typeMethod === 'publishStock' && renderFrame === 2 ? (
                        <S.RowTitle style={{ justifyContent: 'flex-start', alignItems: 'center', maxWidth: '30%' }}>
                            <Button
                                style={{ width: width < 400 ? 160 : 200, height: width < 600 ? (width < 400 ? 30 : 40) : 50, marginRight: 20 }}
                                onPress={() => {
                                    if (existVehicle) {
                                        setOpenGallery(true);
                                    } else {
                                        LoadPhoto(true);
                                    }
                                }}
                                label="Carregar Fotos"
                                loading={loading}
                            />
                        </S.RowTitle>
                    ) : null}
                    {renderFrame === 2 || renderFrame === 3 ? (
                        <>
                            <S.InfoArea width={width} onContextMenu={(e) => e.preventDefault()}>
                                {renderStepFrame()}
                            </S.InfoArea>
                            {rendeButtonFrames()}
                            <LoadingModal
                                visible={loadingDownload}
                                titleLoading='Baixando imagens...'
                            />
                            {typeMethod === 'publishStock' && renderFrame === 2 && type !== 'banner-site' ? renderDescriptionArea() : null}
                            {direct && typeMethod !== 'publishStock' && type !== 'banner-site' ? renderDescriptionArea() : null}
                            {type !== 'banner-site' && ((photosForTemplate?.id && !direct && typeMethod !== 'publishStock') || (!is_vehicle && typeMethod !== 'publishStock')) ? renderDescriptionArea(!is_vehicle && typeMethod !== 'publishStock') : null}
                            {renderButtonsScheduling()}
                            <SchedulingModal
                                modalVisible={schedulingModalVisible}
                                setModalVisible={setSchedulingModalVisible}
                                idVehicle={selectedTemplateOption?.id || id_vehicle || photosForTemplate?.id}
                                description={description || ''}
                                previewThumb={typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected}
                                idArt={id}
                                setTemplateModalVisible={setTemplateModalVisible}
                                setUseTemplateModalVisible={setModalVisible}
                                type={typeMethod}
                                updateList={updateList}
                                photoVehicleSelected={typeMethod === 'publishStock' ? photosForTemplateLocal : photosForTemplateSelected}
                                logoSelected={imagesSelecteds[0]?.photo || ''}
                                direct={direct}
                                getUrlPhotos={getUrlPhotos}
                                selectedTemplateOption={selectedTemplateOption}
                                handleBackdropPress={handleBackdropPress}
                                titleVehicle={titleVehicleEdit}
                                texts={textsForFrame}
                            />
                        </>
                    ) : null}
                    <CoditionModal
                        titleModal={modalInfo.titleModal}
                        descriptionModal={modalInfo.descriptionModal}
                        typeModal={modalInfo.typeModal}
                        buttonText={modalInfo.buttonText}
                        modalVisible={modalInfo.modalVisible}
                        setModalVisible={modalInfo.setModalVisible}
                        buttonFunction={modalInfo.buttonFunction}
                    />
                    <ModalSend
                        modalVisible={modalInfoSend}
                        setModalVisible={setModalInfoSend}
                        type={type}
                        isPublishSite={isPublishSite}
                        userInfo={userInfo}
                    />
                    <Modal
                        isVisible={openGallery}
                        closeModal={() => {
                            setOpenGallery(!openGallery);
                            if (typeMethod === 'publishStock') {
                                // setVehiclesListLocal([]);
                                // setPhotosForTemplateLocal([]);
                                setPhotosForTemplate(null);
                                setAllVehiclePhotos([]);
                                setPhotosForTemplateSelected([]);
                                setPhotosForTemplateSelectedBackup([]);
                                setPhotosForTemplateSelectedAll([]);
                            }
                        }}
                    >
                        <S.ContainerModalGallery>
                            <S.ScrollFilters>
                                {photosForTemplate?.photo?.length > 0 ? (
                                    <S.ButtonModalAlign style={{ marginTop: 20, marginLeft: 20 }}>
                                        <S.ButtonModalArea
                                            background="purple"
                                            style={{ marginTop: 10, marginRight: 10, paddingRight: 5, paddingLeft: 5, width: width <= 650 ? (width <= 500 ? 80 : 90) : 150, height: width <= 400 ? 25 : 40 }}
                                            onPress={handleSelectAll}>
                                            <S.ButtonModalText style={{ fontSize: width <= 650 ? (width <= 500 ? 12 : 12) : 16 }}>
                                                Marcar todas
                                            </S.ButtonModalText>
                                        </S.ButtonModalArea>
                                        <S.ButtonModalArea
                                            background="purple"
                                            style={{ marginTop: 10, marginRight: 10, paddingRight: 5, paddingLeft: 5, width: width <= 650 ? (width <= 500 ? 100 : 110) : 150, height: width <= 400 ? 25 : 40 }}
                                            onPress={handleDeselectAll}>
                                            <S.ButtonModalText style={{ fontSize: width <= 650 ? (width <= 500 ? 12 : 12) : 16 }}>
                                                Desmarcar todas
                                            </S.ButtonModalText>
                                        </S.ButtonModalArea>
                                    </S.ButtonModalAlign>
                                ) : null}
                                <S.AdjustFilters width={window}>
                                    {/* {typeMethod === 'publishStock' ? renderGalleryPublishStock() : renderGallery()} */}
                                    {renderGalleryPublishStock()}
                                </S.AdjustFilters>
                                <Button style={{ width: 200, marginTop: 20, marginBottom: 20, backgroundColor: theme.colors.blue[100] }} onPress={() => LoadPhoto(true, true)} label="Carregar Fotos" loading={loading} />
                                {!vehiclesListLocal.length ? null : (
                                    <S.CarListArea>
                                        <S.AlignCarArea width={width} contentContainerStyle={{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            alignItems: 'center',
                                            // justifyContent: 'center',
                                        }}>
                                            {RenderCarList(true)}
                                        </S.AlignCarArea>
                                        {RenderBackIcon()}
                                        {RenderNextIcon()}
                                    </S.CarListArea>
                                )}
                                {errorModalLimitePhoto ? (
                                    <S.ButtonModalText style={{ color: theme.colors.red[600], marginBottom: 20 }}>
                                        Não é possível adicionar mais de 10 imagens por vez.
                                    </S.ButtonModalText>
                                ) : null}
                                <S.ButtonModalAlign>
                                    {/* <S.ButtonModalArea
                                        background="purple"
                                        style={{ marginTop: 10, marginRight: 10, paddingRight: 5, paddingLeft: 5, width: width <= 650 ? (width <= 500 ? 80 : 90) : 150, height: width <= 400 ? 25 : 40 }}
                                        onPress={handleSelectAll}>
                                        <S.ButtonModalText style={{ fontSize: width <= 650 ? (width <= 500 ? 12 : 12) : 16 }}>
                                            Marcar todas
                                        </S.ButtonModalText>
                                    </S.ButtonModalArea>
                                    <S.ButtonModalArea
                                        background="purple"
                                        style={{ marginTop: 10, marginRight: 10, paddingRight: 5, paddingLeft: 5, width: width <= 650 ? (width <= 500 ? 100 : 110) : 150, height: width <= 400 ? 25 : 40 }}
                                        onPress={handleDeselectAll}>
                                        <S.ButtonModalText style={{ fontSize: width <= 650 ? (width <= 500 ? 12 : 12) : 16 }}>
                                            Desmarcar todas
                                        </S.ButtonModalText>
                                    </S.ButtonModalArea> */}
                                    <S.ButtonModalArea
                                        background="purple"
                                        style={{ marginTop: 10 }}
                                        onPress={changeModalVisible}>
                                        <S.ButtonModalText>
                                            Confirmar seleção
                                        </S.ButtonModalText>
                                    </S.ButtonModalArea>
                                </S.ButtonModalAlign>
                            </S.ScrollFilters>
                        </S.ContainerModalGallery>
                    </Modal>
                    <LoadingModal
                        visible={loadingVisible}
                        titleLoading="Estamos preparando sua publicação..."
                        // titleLoading='Criando a publicação nas redes sociais selecionadas. O tempo pode variar dependendo da quantidade de fotos e da velocidade de sua internet, não ultrapassando 25 segundos.'
                    />
                    <Modal isVisible={contactModalVisible} closeModal={setContactModalVisible}>
                        <InfoModal userInfo={userInfo?.user} description={contactModalInfo.description} setModalVisible={setContactModalVisible} />
                    </Modal>
                    <Modal isVisible={socialMediaVisible} closeModal={setSocialMediaVisible}>
                        <TypeSocialModal onPressConfirm={confirmSocialPress} setTypeSocial={setTypeSocial} typeSocial={typeSocial} />
                    </Modal>
                    <Modal isVisible={modalEnabledRemoveBg} closeModal={handleModalRemoveBg}>
                        <S.ContainerModalConfirm width={width}>
                            <S.TextDefault
                                fontSize={25}
                                fontFamily="bold"
                                color={fontBlack} style={{ textAlign: 'center' }}>
                                Confirma ação de remover fundo avançado?
                            </S.TextDefault>
                            <S.Label>Taxa de R$0,99 por imagem recortada.</S.Label>
                            <S.ButtonModalConfirm>
                                <S.ButtonModalArea
                                    background="red"
                                    onPress={handleModalRemoveBg}
                                    style={{ width: width < 400 ? (width < 350 ? (width < 300 ? 75 : 100) : 125) : 150 }}
                                >
                                    <S.ButtonModalText style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        Cancelar
                                    </S.ButtonModalText>
                                </S.ButtonModalArea>
                                <S.ButtonModalArea
                                    background="purple"
                                    onPress={() => {
                                        handleModalRemoveBg();
                                        if (typeMethod === 'publishStock') {
                                            if (typeArt !== 'molduras') {
                                                applyRemoveBg();
                                            } else {
                                                const updateImagesWithFrameSelected = imagesWithFrameSelected.map((itemRemoveBg) => {
                                                    if (itemRemoveBg.id === photoRemoveBgActual?.id) {
                                                        return {
                                                            ...itemRemoveBg,
                                                            removeBg: true,
                                                        };
                                                    }
                                                    return itemRemoveBg;
                                                });
                                                setImagesWithFrameSelected(updateImagesWithFrameSelected);
                                                applyFrameCrop(updateImagesWithFrameSelected, framesSelecteds, photoRemoveBgActual);
                                            }
                                        } else if (typeMethod === 'publishStock' && openCrop) {
                                            applyRemoveBg();
                                        } else {
                                            generatorTemplate(undefined, true);
                                        }
                                    }}
                                    style={{ width: width < 400 ? (width < 350 ? (width < 300 ? 75 : 100) : 125) : 150 }}
                                >
                                    <S.ButtonModalText style={{ fontSize: width < 350 ? 12 : 16, paddingLeft: 5, paddingRight: 5 }}>
                                        Confirmar recorte
                                    </S.ButtonModalText>
                                </S.ButtonModalArea>
                            </S.ButtonModalConfirm>
                        </S.ContainerModalConfirm>
                    </Modal>
                </S.ModalArea>
            </S.ScrollView>
            <Toaster position="top-right" reverseOrder={true} />
        </S.ContainerModal>
    );
}
